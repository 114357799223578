@font-face {
  font-family: "Ubuntu";
  src: url("/public/assets/Ubuntu/Ubuntu-Medium.ttf");
}

h1, h2, h3, h4, h5, h6 {
  color: #fff !important;
}

#map {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.mdg-bg-nav {
  background: #081217;
  color: #fff !important;
  border-radius: 10px;
}

.mdg-logo-meta {
  width: 20%;
}

.mdg-font-text {
  color: #b1aeae !important;
}

a.nav-link {
  color: #b1aeae !important;
}

a.navbar-brand {
  width: 18em;
}

/* span i.mdg-fa-plus {
    background: #404950;
  } */

.dropdown-menu {
  right: 0 !important;
  left: auto;
}

.dropdown-menu.show {
  right: 0 !important;
  left: auto;
}

::-webkit-input-placeholder {
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

::placeholder {
  color: #fff;
}

header h1 {
  color: #fff;
  font-size: 150px;
}

.intro {
  margin-top: 15%;
}

header h4 {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
}

.bg-meta {
  color: #00ccfe;
  font-weight: bold;
}

.mdg-width-d {
  height: 850px;
}

.navbar-nav > li > a {
  margin: 0 5px;
}

.navbar-nav > li > a:hover,
.navbar-nav > li > a:active,
.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  color: #fff !important;
  background: #181f23;
  border-radius: 5px;
  margin: 0 5px;
}

.dropdown-menu > a.dropdown-item:hover,
.dropdown-menu > a.dropdown-item:focus {
  color: #fff !important;
  background-color: #181f23 !important;
}

.rounded-circle-sm {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.rounded-card-sm {
  height: 15px;
  width: 15px;
}

.rounded-card-md {
  height: 21px;
  width: 21px;
}

.rounded-card-small {
  height: 17px;
  width: 17px;
}

.rounded-circle-lg {
  height: 100px;
  width: 100px;
  object-fit: fill;
}

.dropdown-menu > a.dropdown-item:hover,
.dropdown-menu > a.dropdown-item:focus {
  color: #fff !important;
  background-color: #181f23 !important;
}

.mdg-padding-ingame {
  padding: 80px 250px;
}

.mdg-title-ingame {
  padding: 20px 20px;
  color: #fff;
}

.mdg-search-place {
  position: absolute;
  right: 15px;
  z-index: 1;
}

/* .mdg-fa {
    left: 5px !important;
    color: #fff;
} */

.mdg-width-card-item {
  /* height: 620px; */
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}

.mdg-width-card-item::-webkit-scrollbar {
  display: none;
}

.tab-content {
  color: #fff !important;
}

.mdg-title-ingame {
  padding: 20px 0px;
  color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #17a2b8 !important;
  background: #181f23 !important;
  border-radius: 5px;
  margin: 0 5px;
}

.nav-pills .nav-link {
  border-radius: 5px;
  margin: 0 5px;
}

.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  color: #17a2b8 !important;
  background: #181f23 !important;
}

.mdg-search-select {
  background: transparent;
  color: white;
  border-radius: 50px;
}

.mdg-search-select option {
  background: #00ccfe !important;
  color: #fff;
}

.mdg-search-select option:hover,
.mdg-search-select option:active,
.mdg-search-select option:focus {
  background: #fff !important;
  color: #b1aeae;
}

.mdg-search-input {
  background: transparent;
  color: #b1aeae;
  border-radius: 5px;
  border: 1px solid #b1aeae;
  padding: 2px 0px 2px 30px;
  height: 39px;
}

select.mdg-select-search {
  appearance: none;
  outline: 0;
  border: 1px solid #b1aeae;
  box-shadow: none;
  flex: 1;
  padding: 0 1em;
  color: #fff;
  background-color: #081217;
  background-image: none;
  cursor: pointer;
  border-radius: 50px;
}

select.mdg-select-search::-ms-expand {
  display: none;
}

.select-search {
  position: relative;
  display: flex;
  height: 40px;
  border-radius: 0.25em;
  overflow: hidden;
}

.select-search::after {
  content: "\25BC";
  position: absolute;
  top: -7px;
  right: 0;
  padding: 1em;
  transition: 0.25s all ease;
  pointer-events: none;
}

.mdg-search-place .fa-search {
  position: absolute;
  left: 10px;
  top: 10px;
}

.mdg-icon-heart {
  color: #00ccfe;
  margin: 10px;
  cursor: pointer;
}

.mdg-card-body-items {
  position: absolute;
  /* margin-top: 35px; */
  width: 100%;
}

.mdg-card-footer-items {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #fff !important;
  padding: 0.75rem 0.5rem !important;
}

.mdg-card-footer-flight {
  padding: 0.5rem 0.75rem !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #fff !important;
}

.mdg-title-ingame .mdg-h2 {
  font-size: 2rem !important;
}

.mdg-text-logo {
  font-size: 13px !important;
}

.mdg-text-marque {
  font-size: 12px;
  text-transform: uppercase;
}

.mdg-bg-info {
  color: #00ccfe;
}

.mdg-nbr-0 {
  background: #34393c !important;
}

.mdg-image-body-sale {
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  object-fit: contain;
}

.mdg-bg-btn-group {
  background: #414c52;
  padding: 4px;
  border-radius: 20px;
}

.mdg-btn-meta {
  color: #fff;
}

.mdg-btn-meta:hover,
.mdg-btn-meta.active {
  color: #fff !important;
  background-color: #081217;
  border-color: #081217;
  border-radius: 20px 20px 20px 20px !important;
}

.mdg-bg-common {
  color: #fff;
  background-color: #414c52;
  border-color: #414c52;
  border-radius: 7px;
  text-transform: uppercase;
  padding: 3px 6px;
}

.mdg-btn-common {
  color: #fff !important;
  background-color: #414c52 !important;
  border-color: #414c52 !important;
  border-radius: 7px;
}

.mdg-btn-meta-payment {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  border-radius: 7px;
}

.mdg-btn-common:hover {
  color: #fff;
  background-color: #232a2e !important;
  border-color: #232a2e !important;
}

.mdg-bg-unique {
  color: #fff;
  background-color: #0d99ff;
  border-color: #0d99ff;
  border-radius: 7px;
  padding: 3px 6px;
}

.mdg-btn-unique {
  color: #fff;
  background-color: #0d99ff;
  border-color: #0d99ff;
  border-radius: 7px;
}

.mdg-btn-unique:hover {
  color: #fff;
  background-color: #0e82d4;
  border-color: #0e82d4;
}

.mdg-bg-rare {
  color: #96540a;
  background-color: #f8ba01;
  border-color: #f8ba01;
  border-radius: 7px;
  padding: 3px 6px;
}

.mdg-btn-rare {
  color: #96540a;
  background-color: #f8ba01;
  border-color: #f8ba01;
  border-radius: 7px;
}

.mdg-btn-rare:hover {
  color: #96540a;
  background-color: #f8ba01da;
  border-color: #f8ba01da;
}

.mdg-card-header-flight {
  position: relative;
  min-height: 200px;
  max-height: 200px;
}

.mdg-image-flight {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: 0;
  border-radius: 10px 10px 0 0;
}

.mdg-heart-flight {
  position: absolute;
  right: 0;
  z-index: 1;
  color: #fff !important;
  margin: 10px;
  cursor: pointer;
}
/* start card*/

.mdg-width-card-body {
  position: relative;
  height: 450px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #404950, #171f22);
}

.mdg-width-card-body-v2 {
  position: relative;
  height: 400px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #404950, #171f22);
}

.mdg-width-card-body,
.mdg-width-card-body::before,
.mdg-width-card-body::after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.mdg-width-card-body:hover {
  transform: scale(1.03, 1.03);
}

.mdg-width-card-profil-body {
  padding: 10px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #404950, #171f22);
}

.mdg-width-card-profil-information {
  padding: 10px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #404950, #171f22);
}

.mdg-width-card-profil-body:hover {
  transform: scale(1.02, 1.02);
}

.mdg-width-card-body-flight {
  position: relative;
  height: 370px;
  border-radius: 10px;
  background: #181f23;
}

.mdg-btn-header-flight {
  z-index: 1;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

ul.mdg-list {
  position: absolute;
  margin-top: -35px;
  left: 35px;
  list-style: none;
}

ul.mdg-lists {
  position: absolute;
  list-style: none;
  left: 15px;
}

.mdg-list .fa-star {
  color: #f8ba01;
}

.mdg-card-header-items {
  position: absolute;
  top: 0;
  right: 0;
}

.mdg-border-link {
  border-left: 1px solid #646363;
}

.mdg-bg-text-items {
  color: #fff;
  font-size: 11px !important;
}

.mdg-text-marque {
  font-size: 12px;
  text-transform: uppercase;
}

.mdg-image-body {
  width: 100%;
  min-height: 210px;
  max-height: 210px;
  object-fit: contain;
}

/* end card*/

.mdg-d-block {
  display: flex;
}

.mdg-top-container {
  margin-top: 6em;
}

.leaflet-top {
  top: 80px !important;
}

.mdg-13-text {
  font-size: 13px;
}

.mdg-bl-details-missions {
  border-left: 1px solid;
  border-left-style: dashed;
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg)
    saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
    filter: var(--map-tiles-filter, none);
  }
}

/* @media only screen and (max-width: 1450px) {
  .container {
    max-width: 1140px;
  }
} */

@media (min-width: 768px) and (max-width: 1450px) {
  .mdg-list-item {
    margin-top: 4px !important;
  }

  .mdg-setting-title {
    margin-bottom: 0px !important;
  } 
  
  .mdg-off-active {
    padding: 0px !important;
  }

  .mdg-on-active {
    padding: 0px !important;
  }

  .mdg-pos-absolute {
    top: -30px !important;
  }

  .mdg-card-setting {
    height: auto !important;
  }

}

@media only screen and (min-width: 1450px) {
  .container {
    max-width: 1140px;
  }

  .os-content {
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 1450px) {
  a.navbar-brand {
    width: 13em;
  }
  .container {
    max-width: 1030px;
  }
  .os-content {
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 1400px) {
  a.navbar-brand {
    width: 8em;
  }
 

  .md-sm-3 {
    margin-top: 5px;
  }
  .os-content {
    overflow-y: scroll;
  }
}

/* @media only screen and (max-width: 767px) {
    .os-content {
        overflow-y: scroll;
    }
} */

@media only screen and (max-width: 767px) {
  .os-content {
    overflow-y: scroll;
  }

  .mdg-border-md {
    border-bottom: 1px solid #979797;
    padding: 5px;
  }

  .mdg-head-login {
    width: 60% !important;
  }

  .mdg-centers {
    width: 100% !important;  
    left: 5px !important;
    right: 5px !important;
    transform: translate(0%, -50%) !important;
  }
  .mdg-border-left-top-end {
    border-radius: 10px 10px 0 0 !important;
  }
  .mdg-border-right-top-end {
    border-radius: 0 0 10px 10px !important;
  }

  .mdg-center {
    right: 50% !important;
    transform: translate(-51%, -50%) !important;
  }

  .mdg-search-place {
    position: unset;
    right: 15px;
    z-index: 1;
    width: 100%;
  }

  .mdg-search-place .fa-search {
    left: 20px;
  }

  .mdg-float-right {
    float: none !important;
  }

  .col-sm {
    text-align: center;
  }

  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mdg-b-detail {
    position: relative !important;
    bottom: 0px;
    right: 0;
    color: #b1aeae !important;
  }
  .mdg-blur {
    padding: 0px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
  .mdg-header-d {
    background-color: #fff !important;
  }

  header h1 {
    font-size: 70px;
  }

  .md-sm-3 {
    margin-top: 5px;
  }
  .md-sm-6 {
    margin-top: 40px;
  }

  .mdg-btn-12 {
    width: 100%;
  }

  .mdg-top-container {
    margin-top: 3em;
  }

  .mdg-fa-bar-mobile {
    color: #fff;
  }

  .mdg-width-card-item {
    height: auto;
    margin-top: 50px;
    overflow-y: inherit;
  }

  .mdg-d-block {
    display: block;
  }

  .navbar-collapse.collapse.show {
    background: #081217;
    text-align: center;
  }

  .mdg-bg-nav {
    position: fixed;
    background: #081217;
    color: #fff !important;
    padding: 1px 1px;
    height: 50px;
    margin-top: 0;
  }

  .mdg-padding-ingame {
    padding: 10px 0px;
  }

  .mdg-search-line .col-sm-2 {
    margin-left: 15px;
    margin-top: 5px;
  }

  .mdg-pos-absolute {
    top: 25px !important;
  }
}

.mdg-btn {
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border-radius: 5px;
  border: 1px solid;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  background-color: transparent;
  cursor: pointer;
  line-height: 1.5;
  width: 100%;
}

.mdg-btn-circle.mdg-btn-lg {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 25px;
  text-align: center;
}

.mdg-btn-circle.mdg-btn-sm {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 19px;
  text-align: center;
}

.mdg-btn-circle.mdg-btn-xs {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 11px;
  text-align: center;
}

.mdg-btn-square.mdg-btn-lg {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 2px;
  font-size: 25px;
  text-align: center;
}

.mdg-btn-square.mdg-btn-sm {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 2px;
  font-size: 19px;
  text-align: center;
}

.mdg-btn-square.mdg-btn-xs {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 2px;
  font-size: 11px;
  text-align: center;
}

.mdg-btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.mdg-btn-danger {
  background: #c82333;
  color: #fff;
}

.mdg-btn-danger.hover,
.mdg-btn-danger:active,
.mdg-btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.mdg-btn-primary {
  background: #007bff;
  color: #fff;
}

.mdg-btn-primary.hover,
.mdg-btn-primary:active,
.mdg-btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.mdg-btn-madigi {
  background: #fff;
  color: #fff;
}

.mdg-btn-madigi.hover,
.mdg-btn-madigi:active,
.mdg-btn-madigi:hover {
  color: #fff;
  background-color: #2b0f0e;
  border-color: #330805;
}

.mdg-btn-warning {
  background: #ffc107;
  color: #fff;
}

.mdg-btn-warning.hover,
.mdg-btn-warning:active,
.mdg-btn-warning:hover {
  color: #fff;
  background-color: #d39e00;
  border-color: #d39e00;
}

.mdg-btn-success {
  background: #28a745;
  color: #fff;
}

.mdg-btn-success.hover,
.mdg-btn-success:active,
.mdg-btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.mdg-btn-dark {
  background: #000000;
  color: #fff;
}

.mdg-btn-dark.hover,
.mdg-btn-dark:active,
.mdg-btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.mdg-btn-default {
  background: #fff;
  color: #444;
}

.mdg-btn-default.hover,
.mdg-btn-default:active,
.mdg-btn-default:hover {
  background-color: #e9ecef;
  color: #2b2b2b;
}

.mdg-btn-light {
  background: #fff;
  color: #444;
}

.mdg-btn-light.hover,
.mdg-btn-light:active,
.mdg-btn-light:hover {
  background-color: #e9ecef;
  color: #2b2b2b;
}

.mdg-btn-round {
  border-radius: 50px !important;
}

.mdg-btn-outline-danger {
  border-color: #c82333;
  color: #c82333;
}

.mdg-btn-danger.mdg-btn-disabled,
.mdg-btn-danger:disabled {
  color: #fff;
  background-color: #c82333;
  border-color: #c82333;
}

.mdg-btn-outline-danger.mdg-btn-disabled,
.mdg-btn-outline-danger:disabled {
  color: #fff;
  background-color: #c82333;
  border-color: #c82333;
}

.mdg-btn-outline-danger.hover,
.mdg-btn-outline-danger:active,
.mdg-btn-outline-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.mdg-btn-outline-primary {
  border-color: #007bff;
  color: #007bff;
}

.mdg-btn-outline-primary.hover,
.mdg-btn-outline-primary:active,
.mdg-btn-outline-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.mdg-btn-primary.mdg-btn-disabled,
.mdg-btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.mdg-btn-outline-primary.mdg-btn-disabled,
.mdg-btn-outline-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.mdg-btn-outline-warning {
  border-color: #ffc107;
  color: #ffc107;
}

.mdg-btn-warning.mdg-btn-disabled,
.mdg-btn-warning:disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.mdg-btn-outline-warning.mdg-btn-disabled,
.mdg-btn-outline-warning:disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.mdg-btn-outline-warning.hover,
.mdg-btn-outline-warning:active,
.mdg-btn-outline-warning:hover {
  color: #fff;
  background-color: #d39e00;
  border-color: #d39e00;
}

.mdg-btn-outline-success {
  border-color: #28a745;
  color: #28a745;
}

.mdg-btn-outline-success.mdg-btn-disabled,
.mdg-btn-outline-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.mdg-btn-success.mdg-btn-disabled,
.mdg-btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.mdg-btn-outline-success.hover,
.mdg-btn-outline-success:active,
.mdg-btn-outline-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.mdg-btn-outline-dark {
  border-color: #000000;
  color: #000000;
}

.mdg-btn-dark.mdg-btn-disabled,
.mdg-btn-dark:disabled {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.mdg-btn-outline-dark.mdg-btn-disabled,
.mdg-btn-outline-dark:disabled {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.mdg-btn-outline-dark.hover,
.mdg-btn-outline-dark:active,
.mdg-btn-outline-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.mdg-btn-outline-meta {
  border-color: #fff;
  color: #fff;
}

.mdg-btn-outline-meta-login {
  border-color: #394263;
  color: #394263;
}
.mdg-btn-outline-meta-login:hover {
  border-color: #394263;
  background-image: linear-gradient(
    to bottom right,
    #394263,
    #00ccfe
  ) !important;
  color: #fff;
}

.mdg-btn-meta.mdg-btn-disabled,
.mdg-btn-meta:disabled {
  color: #fff;
  background-image: linear-gradient(
    to bottom right,
    #394263,
    #00ccfe
  ) !important;
  border-color: #fff;
}

.mdg-btn-outline-meta.mdg-btn-disabled,
.mdg-btn-outline-meta:disabled {
  color: #00ccfe;
  background-image: linear-gradient(
    to bottom right,
    #394263,
    #00ccfe
  ) !important;
  border-color: #fff;
}

.mdg-btn-outline-meta.hover,
.mdg-btn-outline-meta:active,
.mdg-btn-outline-meta:hover {
  color: #fff;
  background-image: linear-gradient(
    to bottom right,
    #394263,
    #00ccfe
  ) !important;
  border-color: #fff;
}

.mdg-btn-outline-madigi {
  border-color: #fff;
  color: #fff;
}

.mdg-btn-madigi.mdg-btn-disabled,
.mdg-btn-madigi:disabled {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.mdg-btn-outline-madigi.mdg-btn-disabled,
.mdg-btn-outline-madigi:disabled {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.mdg-btn-outline-madigi.hover,
.mdg-btn-outline-madigi:active,
.mdg-btn-outline-madigi:hover {
  color: #fff;
  background-color: #2b0f0e;
  border-color: #2b0f0e;
}

.mdg-btn-outline-light {
  border-color: #fff;
  color: #fff;
}

.mdg-btn-outline-light.hover,
.mdg-btn-outline-light:active,
.mdg-btn-outline-light:hover {
  background-color: #e9ecef;
  color: #2b2b2b !important;
}

.mdg-btn-group-lg > .mdg-btn,
.mdg-btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.mdg-btn-group-sm > .mdg-btn,
.mdg-btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.mdg-btn-xs {
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.15rem;
}

.mdg-btn.mdg-btn-flat {
  border-radius: 0 !important;
  border-width: 1px;
  box-shadow: none;
}

.mdg-btn.mdg-btn-disabled,
.mdg-btn:disabled {
  cursor: not-allowed !important;
}

.mdg-btn.mdg-btn-disabled,
.mdg-btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.mdg-body-ingame {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  background: url("../../../public/assets/img/bg-other.png") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mdg-bg-details-vols {
  width: 100%;
  height: 90px;
  top: 0;
  background: url("../../../public/assets/img/vols-detail.png") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mdg-header-ingame {
  background-image: linear-gradient(
    to bottom,
    transparent,
    #081217,
    #081217,
    #081217,
    #081217
  ) !important;
  padding-bottom: 50px;
  height: 500px;
  z-index: 6;
  position: relative;
}

.mdg-header-ingames {
  background: transparent !important;
  padding-bottom: 50px;
  height: 500px;
  z-index: 6;
  position: relative;
}

.mdg-img-description {
  width: 10%;
}

.mdg-col,
.mdg-col-1,
.mdg-col-10,
.mdg-col-11,
.mdg-col-12,
.mdg-col-2,
.mdg-col-3,
.mdg-col-4,
.mdg-col-5,
.mdg-col-6,
.mdg-col-7,
.mdg-col-8,
.mdg-col-9,
.mdg-col-auto,
.mdg-col-lg,
.mdg-col-lg-1,
.mdg-col-lg-10,
.mdg-col-lg-11,
.mdg-col-lg-12,
.mdg-col-lg-2,
.mdg-col-lg-3,
.mdg-col-lg-4,
.mdg-col-lg-5,
.mdg-col-lg-6,
.mdg-col-lg-7,
.mdg-col-lg-8,
.mdg-col-lg-9,
.mdg-col-lg-auto,
.mdg-col-md,
.mdg-col-md-1,
.mdg-col-md-10,
.mdg-col-md-11,
.mdg-col-md-12,
.mdg-col-md-2,
.mdg-col-md-3,
.mdg-col-md-4,
.mdg-col-md-5,
.mdg-col-md-6,
.mdg-col-md-7,
.mdg-col-md-8,
.mdg-col-md-9,
.mdg-col-md-auto,
.mdg-col-sm,
.mdg-col-sm-1,
.mdg-col-sm-10,
.mdg-col-sm-11,
.mdg-col-sm-12,
.mdg-col-sm-2,
.mdg-col-sm-3,
.mdg-col-sm-4,
.mdg-col-sm-5,
.mdg-col-sm-6,
.mdg-col-sm-7,
.mdg-col-sm-8,
.mdg-col-sm-9,
.mdg-col-sm-auto,
.mdg-col-xl,
.mdg-col-xl-1,
.mdg-col-xl-10,
.mdg-col-xl-11,
.mdg-col-xl-12,
.mdg-col-xl-2,
.mdg-col-xl-3,
.mdg-col-xl-4,
.mdg-col-xl-5,
.mdg-col-xl-6,
.mdg-col-xl-7,
.mdg-col-xl-8,
.mdg-col-xl-9,
.mdg-col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.mdg-text-right {
  text-align: right;
}

@media (min-width: 768px) {
  .mdg-text-right {
    text-align: center;
  }
  .mdg-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mdg-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdg-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdg-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mdg-col-lg-10 {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%; 
  }
  .mdg-col-sm-10 {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .mdg-col-md-10 {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .mdg-col-md-8 {
    -ms-flex: 0;
    flex: 0 0 66.666666666%;
    max-width: 66.666666666%;
  }
  .mdg-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    /* max-width: 16.666667%; */
  }
  .mdg-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    /* max-width: 16.666667%; */
  }
  .mdg-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    /* max-width: 16.666667%; */
  }
  .mdg-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    /* max-width: 33.333333%; */
  }
  .mdg-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    /* max-width: 33.333333%; */
  }
  .mdg-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    /* max-width: 33.333333%; */
  }
  .mdg-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    /* max-width: 41.666667%; */
  }
  .mdg-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    /* max-width: 41.666667%; */
  }
  .mdg-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    /* max-width: 41.666667%; */
  }
}

.mdg-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.mdg-card-body::after,
.mdg-card-footer::after,
.mdg-card-header::after {
  display: block;
  clear: both;
  content: "";
}

.mdg-card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.mdg-card-body > .mdg-table {
  margin-bottom: 0;
}

.leaflet-top {
  top: 80px !important;
}

.side-navbar-a,
.drop-button {
  display: block;
  padding: 10px 8px;
  border: none;
  color: white;
  background: inherit;
  font-size: 20px;
  width: 100%;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

#dropdown-container {
  background: transparent;
  padding-left: 8px;
}

#dropdown-container a {
  color: #fff;
  display: block;
  padding: 10px 12px;
  text-decoration: none;
}

.mdg-card-profil-flight {
  background: transparent !important;
  color: #b1aeae !important;
}

.mdg-b-detail {
  position: absolute;
  bottom: 20px;
  right: 0;
  color: #b1aeae !important;
}

a:hover {
  color: #fff !important;
  background: #181f23;
}
a.nav-link:hover {
  color: #fff !important;
  background: #181f23;
}

a.sidenav__brand-link:hover {
  color: #fff !important;
  background: transparent !important;
}

:focus {
  outline: none;
}

::placeholder {
  color: #333;
}

input[type="email"],
input[type="text"] {
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.mdg-form-group-animate {
  float: left;
  width: 100%;
  margin: 5px 1%;
  position: relative;
}

.mdg-effect-1,
.mdg-effect-2,
.mdg-effect-3 {
  border: 0;
  padding: 7px 0;
  border-bottom: 1px solid #ccc;
}

.mdg-effect-1 ~ .mdg-focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: 0.4s;
}

.mdg-effect-1:focus ~ .mdg-focus-border {
  width: 100%;
  transition: 0.4s;
}

.mdg-effect-2 ~ .mdg-focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: 0.4s;
}

.mdg-effect-2:focus ~ .mdg-focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

.mdg-effect-3 ~ .mdg-focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 99;
}

.mdg-effect-3 ~ .mdg-focus-border:before,
.mdg-effect-3 ~ .mdg-focus-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #fff;
  transition: 0.4s;
}

.mdg-effect-3 ~ .mdg-focus-border:after {
  left: auto;
  right: 0;
}

.mdg-effect-3:focus ~ .mdg-focus-border:before,
.mdg-effect-3:focus ~ .mdg-focus-border:after {
  width: 50%;
  transition: 0.4s;
}

.mdg-effect-4,
.mdg-effect-5,
.mdg-effect-6 {
  border: 0;
  padding: 5px 0 7px;
  border: 1px solid transparent;
  border-bottom-color: #ccc;
  transition: 0.4s;
}

.mdg-effect-4:focus,
.mdg-effect-5:focus,
.mdg-effect-6:focus {
  padding: 5px 14px 7px;
  transition: 0.4s;
}

.mdg-effect-4 ~ .mdg-focus-border {
  position: absolute;
  height: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.4s;
  z-index: -1;
}

.mdg-effect-4:focus ~ .mdg-focus-border {
  transition: 0.4s;
  height: 36px;
  border: 2px solid #fff;
  z-index: 1;
}

.mdg-effect-5 ~ .mdg-focus-border {
  position: absolute;
  height: 36px;
  bottom: 0;
  left: 0;
  width: 0;
  transition: 0.4s;
}

.mdg-effect-5:focus ~ .mdg-focus-border {
  width: 100%;
  transition: 0.4s;
  border: 2px solid #fff;
}

.mdg-effect-6 ~ .mdg-focus-border {
  position: absolute;
  height: 36px;
  bottom: 0;
  right: 0;
  width: 0;
  transition: 0.4s;
}

.mdg-effect-6:focus ~ .mdg-focus-border {
  width: 100%;
  transition: 0.4s;
  border: 2px solid #fff;
}

.mdg-effect-7,
.mdg-effect-8,
.mdg-effect-9 {
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
}

.mdg-effect-7 ~ .mdg-focus-border:before,
.mdg-effect-7 ~ .mdg-focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: 0.4s;
}

.mdg-effect-7 ~ .mdg-focus-border:after {
  top: auto;
  bottom: 0;
}

.mdg-effect-7 ~ .mdg-focus-border i:before,
.mdg-effect-7 ~ .mdg-focus-border i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #fff;
  transition: 0.6s;
}

.mdg-effect-7 ~ .mdg-focus-border i:after {
  left: auto;
  right: 0;
}

.mdg-effect-7:focus ~ .mdg-focus-border:before,
.mdg-effect-7:focus ~ .mdg-focus-border:after {
  left: 0;
  width: 100%;
  transition: 0.4s;
}

.mdg-effect-7:focus ~ .mdg-focus-border i:before,
.mdg-effect-7:focus ~ .mdg-focus-border i:after {
  top: 0;
  height: 100%;
  transition: 0.6s;
}

.mdg-effect-8 ~ .mdg-focus-border:before,
.mdg-effect-8 ~ .mdg-focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: 0.3s;
}

.mdg-effect-8 ~ .mdg-focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.mdg-effect-8 ~ .mdg-focus-border i:before,
.mdg-effect-8 ~ .mdg-focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #fff;
  transition: 0.4s;
}

.mdg-effect-8 ~ .mdg-focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.mdg-effect-8:focus ~ .mdg-focus-border:before,
.mdg-effect-8:focus ~ .mdg-focus-border:after {
  width: 100%;
  transition: 0.3s;
}

.mdg-effect-8:focus ~ .mdg-focus-border i:before,
.mdg-effect-8:focus ~ .mdg-focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

.mdg-form-group-animate i {
  position: absolute;
}

.mdg-m-icon {
  padding: 15px;
  left: 0;
}

.mdg-mp-3 {
  padding: 7px 40px 9px !important;
}

.mdg-textfield-out-lined {
  position: relative;
  margin-bottom: 5px;
  padding-top: 6px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  width: 100%;
}

.mdg-textfield-out-lined input,
.mdg-textfield-out-lined select,
.mdg-textfield-out-lined textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 9px 13px 9px;
  font-size: 14px;
  line-height: inherit;
  color: #272e36;
  border-style: solid;
  border-width: 1px;
  border-color: transparent #92969a #92969a;
  border-radius: 4px;
  -webkit-text-fill-color: currentColor;
  background-color: transparent;
  caret-color: #fff;
  transition: border 0.2s, box-shadow 0.2s;
}

.mdg-textfield-out-lined.mdg-form-seller input,
.mdg-textfield-out-lined.mdg-form-seller select,
.mdg-textfield-out-lined.mdg-form-seller textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 9px 13px 9px;
  font-size: 14px;
  line-height: inherit;
  color: #272e36;
  border-style: solid;
  border-width: 1px;
  border-color: transparent #17A2B8 #17A2B8;
  border-radius: 4px;
  -webkit-text-fill-color: currentColor;
  background-color: transparent;
  caret-color: #fff;
  transition: border 0.2s, box-shadow 0.2s;
}

.mdg-textfield-out-lined input:not(:focus):placeholder-shown,
.mdg-textfield-out-lined textarea:not(:focus):placeholder-shown {
  border-top-color: #92969a;
}

.mdg-textfield-out-lined input + label,
.mdg-textfield-out-lined textarea + label {
  display: flex;
  width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  font-weight: normal !important;
  left: 0;
  font-size: 12px;
  line-height: 15px;
  color: #92969a;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

.mdg-textfield-out-lined input:not(:focus):placeholder-shown + label,
.mdg-textfield-out-lined textarea:not(:focus):placeholder-shown + label {
  font-size: 14px;
  line-height: 54px;
}

.mdg-textfield-out-lined input + label::before,
.mdg-textfield-out-lined textarea + label::before,
.mdg-textfield-out-lined input + label::after,
.mdg-textfield-out-lined textarea + label::after {
  content: "";
  display: block;
  box-sizing: border-box;
  height: 8px;
  min-width: 10px;
  margin-top: 6px;
  border-top: solid 1px #92969a;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border 0.2s;
}

.mdg-textfield-out-lined.mdg-form-seller input + label::before,
.mdg-textfield-out-lined.mdg-form-seller textarea + label::before,
.mdg-textfield-out-lined.mdg-form-seller input + label::after,
.mdg-textfield-out-lined.mdg-form-seller textarea + label::after {
  content: "";
  display: block;
  box-sizing: border-box;
  height: 8px;
  min-width: 10px;
  margin-top: 6px;
  border-top: solid 1px #17A2B8;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border 0.2s;
}

.mdg-textfield-out-lined input + label::before,
.mdg-textfield-out-lined textarea + label::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.mdg-textfield-out-lined input + label::after,
.mdg-textfield-out-lined textarea + label::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}

.mdg-textfield-out-lined input:not(:focus):placeholder-shown + label::before,
.mdg-textfield-out-lined textarea:not(:focus):placeholder-shown + label::before,
.mdg-textfield-out-lined input:not(:focus):placeholder-shown + label::after,
.mdg-textfield-out-lined textarea:not(:focus):placeholder-shown + label::after {
  border-top-color: transparent;
}

.mdg-textfield-out-lined input:focus,
.mdg-textfield-out-lined textarea:focus {
  border-color: transparent #fff #fff;
  outline: none;
}

.mdg-textfield-out-lined input:focus + label,
.mdg-textfield-out-lined textarea:focus + label {
  color: #fff;
}

.mdg-textfield-out-lined input:focus + label::before,
.mdg-textfield-out-lined textarea:focus + label::before,
.mdg-textfield-out-lined input:focus + label::after,
.mdg-textfield-out-lined textarea:focus + label::after {
  border-top-color: #fff;
}

.mdg-textfield-out-lined input:disabled,
.mdg-textfield-out-lined textarea:disabled,
.mdg-textfield-out-lined input:disabled + label,
.mdg-textfield-out-lined textarea:disabled + label {
  color: #fff;
  border-color: transparent #fff #fff;
  cursor: not-allowed;
}

.mdg-textfield-out-lined input:disabled + label::before,
.mdg-textfield-out-lined textarea:disabled + label::before,
.mdg-textfield-out-lined input:disabled + label::after,
.mdg-textfield-out-lined textarea:disabled + label::after {
  border-top-color: #fff;
}

.mdg-textfield-out-lined input:disabled:placeholder-shown,
.mdg-textfield-out-lined textarea:disabled:placeholder-shown,
.mdg-textfield-out-lined input:disabled:placeholder-shown + label,
.mdg-textfield-out-lined textarea:disabled:placeholder-shown + label {
  border-top-color: #fff;
}

.mdg-textfield-out-lined input:disabled:placeholder-shown + label::before,
.mdg-textfield-out-lined textarea:disabled:placeholder-shown + label::before,
.mdg-textfield-out-lined input:disabled:placeholder-shown + label::after,
.mdg-textfield-out-lined textarea:disabled:placeholder-shown + label::after {
  border-top-color: transparent;
}

.mdg-textfield-out-lined--error input,
.mdg-textfield-out-lined--error textarea {
  border-color: transparent red red;
}

.mdg-textfield-out-lined--error input + label,
.mdg-textfield-out-lined--error textarea + label {
  color: red;
}

.mdg-textfield-out-lined--error input + label::before,
.mdg-textfield-out-lined--error textarea + label::before,
.mdg-textfield-out-lined--error input + label::after,
.mdg-textfield-out-lined--error textarea + label::after {
  border-top-color: red;
}

.mdg-textfield-out-lined--error input:not(:focus):placeholder-shown,
.mdg-textfield-out-lined--error textarea:not(:focus):placeholder-shown,
.mdg-textfield-out-lined--error input:not(:focus):placeholder-shown + label,
.mdg-textfield-out-lined--error textarea:not(:focus):placeholder-shown + label {
  border-top-color: red;
  color: red;
}

.mdg-textfield-out-lined--error
  input:not(:focus):placeholder-shown
  + label::before,
.mdg-textfield-out-lined--error
  textarea:not(:focus):placeholder-shown
  + label::before,
.mdg-textfield-out-lined--error
  input:not(:focus):placeholder-shown
  + label::after,
.mdg-textfield-out-lined--error
  textarea:not(:focus):placeholder-shown
  + label::after {
  border-top-color: transparent;
}

.mdg-textfield-out-lined--error input:focus,
.mdg-textfield-out-lined--error textarea:focus {
  border-color: transparent red red;
}

.mdg-textfield-out-lined--error input:focus + label,
.mdg-textfield-out-lined--error textarea:focus + label {
  color: red;
}

.mdg-textfield-out-lined--error input:focus + label::before,
.mdg-textfield-out-lined--error textarea:focus + label::before,
.mdg-textfield-out-lined--error input:focus + label::after,
.mdg-textfield-out-lined--error textarea:focus + label::after {
  border-top-color: red;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .mdg-textfield-out-lined > input,
    .mdg-textfield-out-lined > input + label,
    .mdg-textfield-out-lined > input + label::before,
    .mdg-textfield-out-lined > input + label::after,
    .mdg-textfield-out-lined > textarea,
    .mdg-textfield-out-lined > textarea + label,
    .mdg-textfield-out-lined > textarea + label::before,
    .mdg-textfield-out-lined > textarea + label::after {
      transition-duration: 0.1s;
    }
  }
}

.container {
  max-width: 1450px !important;
}

.mdg-country > div > div > div > div.css-6j8wv5-Input {
  grid-template-columns: auto !important;
}

.mdg-country > div > div > div > div.css-1pndypt-Input {
  grid-template-columns: auto !important;
}

.css-vwja0k {
  grid-template-columns: auto !important;
}

.mdg-historique-table > .cEMZEX {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-historique-table > div > .jxflYm {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-historique-table > div > div > div > div.fwKvpK {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-historique-table > div > div > div > div > div.eOtTIX {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-historique-table > div > div > div > div > div.hjBMSB {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-historique-table > div > div > div > div > .jVeafb {
  /* background-color: transparent !important; */
  color: #fff !important;
}

.mdg-historique-table > div > nav.iFRVZk {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-history-content {
  border: 1px solid #b1aeae;
  border-radius: 0 40px 0 40px;
  padding: 20px;
}

.mdg-historique-table > div > div > .eSTlnH {
  background-color: transparent !important;
  color: #fff !important;
}
.mdg-historique-table .kVrXuC {
  background-color: transparent !important;
}
.mdg-historique-table .iSAVrt {
  background-color: #454d55 !important;
}
.mdg-historique-table .beUiVL {
  color: #fff !important;
}
.mdg-historique-table .sc-iJnaPW.gKbhqU.rdt_Pagination {
  background-color: #454d55 !important;
  color: #fff !important;
}

.mdg-historique-table > div > div > div > div > .gZWIKX {
  background-image: linear-gradient(
    to bottom right,
    #404950,
    #171f22
  ) !important;
  color: #fff !important;
}

.mdg-historique-table > div > div > div > div.bZtlLv {
  background-image: linear-gradient(
    to bottom right,
    #404950,
    #171f22
  ) !important;
  color: #fff !important;
}

.mdg-blur {
  display: block;
  position: relative;
  background-color: RGBA(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  margin: auto;
}

a.sidebar-item {
  color: #b1aeae !important;
  min-width: 100%;
}

a:active {
  color: #b1aeae !important;
}

.mdg-textfield-out-lined > div > .css-1s2u09g-control {
  background-color: transparent !important;
}

.mdg-textfield-out-lined > div > .css-1insrsq-control {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-textfield-out-lined > div > div > div > .css-1j9hfse-singleValue {
  color: #fff !important;
}

.mdg-textfield-out-lined > input[type="date"] {
  background-color: transparent !important;
}

.mdg-nothing > .mdg-textfield-out-lined {
  padding-top: 0px;
  margin-bottom: 0px;
  line-height: 0.9;
  font-size: 14px;
}

.mdg-nothing > .mdg-textfield-out-lined > input {
  border-color: #b1aeae #b1aeae #b1aeae;
}

.swal2-popup {
  background-color: RGBA(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  border: 1px solid #fff !important;
  border-radius: 0 40px 0 40px !important;
  color: #fff !important;
}

.swal2-confirm.swal2-styled {
  background-color: #00ccfe !important;
}

.swal2-success-circular-line-left {
  background-color: transparent !important;
}

.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  background-color: transparent !important;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  background-color: transparent !important;
}

.swal2-icon.swal2-success .swal2-success-fix {
  background-color: transparent !important;
}

.swal2-icon.swal2-info {
  border-color: #fff !important;
  color: #fff !important;
}

.sidebar-item + #dropdown-container {
  visibility: visible;
  top: 100%;
  width: 100%;
  /* -webkit-transition: all .5s, background, 2s .5s linear; */
  /* transition: all .5s, background 2s .5s linear; */
  /* animation: down 0.5s; */
  animation: bondJamesBond 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  /* transform: translateX(50vw) translateY(50vh); */
}

@keyframes bondJamesBond {
  0% {
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
  }
  90% {
    border-radius: 3px;
  }
  100% {
    border-radius: 3px;
  }
}

@keyframes mymove {
  0% {
    transform: translateX(50vw) translateY(50vh);
  }
  25% {
    transform: translateX(0vw) translateY(0vh);
  }
  75% {
    transform: translateX(0vw) translateY(0vh);
  }
  100% {
    transform: translateX(50vw) translateY(50vh);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes down {
  0% {
    transform: translate3d(0, -200%, 0);
  }

  100% {
    transform: translate3d();
  }
}

/**
start loader
*/

.mdg-icon-loader {
  display: flex;
  text-align: center;
  cursor: default;
  position: fixed;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 20000000000;
  width: 100%;
  background: url("../../../public/assets/img/bg-other.png") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* background: #dddddd; */
  /* background: rgba(0, 0, 0, 0.1); */
}

div.linesquare,
div.texttransform {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  font-size: 2vw;
}
div.linesquare b,
div.texttransform b {
  transform: scaleY(0);
  animation: texttransform_in 1.5s infinite cubic-bezier(0.76, 0, 0.24, 1);
}
div.linesquare b:nth-child(1),
div.texttransform b:nth-child(1) {
  animation-delay: 0s;
}
div.linesquare b:nth-child(2),
div.texttransform b:nth-child(2) {
  animation-delay: 0.15s;
}
div.linesquare b:nth-child(3),
div.texttransform b:nth-child(3) {
  animation-delay: 0.3s;
}
div.linesquare b:nth-child(4),
div.texttransform b:nth-child(4) {
  animation-delay: 0.45s;
}
div.linesquare b:nth-child(5),
div.texttransform b:nth-child(5) {
  animation-delay: 0.6s;
}
div.linesquare b:nth-child(6),
div.texttransform b:nth-child(6) {
  animation-delay: 0.75s;
}
div.linesquare b:nth-child(7),
div.texttransform b:nth-child(7) {
  animation-delay: 0.9s;
}
div.linesquare b:nth-child(8),
div.texttransform b:nth-child(8) {
  animation-delay: 1.05s;
}
div.linesquare b:nth-child(9),
div.texttransform b:nth-child(9) {
  animation-delay: 1.2s;
}
div.linesquare b:nth-child(10),
div.texttransform b:nth-child(10) {
  animation-delay: 1.35s;
}
div.linesquare b {
  width: 2rem;
  height: 2rem;
  background-color: #081217;
}
@keyframes texttransform_in {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  25% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  75% {
    transform-origin: 50% 0%;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scaleY(0);
  }
}

.mdg-loader {
  position: relative;
  height: 150px;
  width: 150px;
  display: block;
  -webkit-animation: around 5.4s infinite;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mdg-loader::after,
.mdg-loader::before {
  content: "";
  background: transparent;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 6px;
  border-color: #00ccfe #083740 transparent transparent;
  border-style: solid;
  border-radius: 50%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.mdg-loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

/**
end loader
*/

.badge {
  background: #17a2b8;
}

[data-after-text],
[data-before-text] {
  --badge-offset-x: calc(0px - var(--badge-size) / 3);
  --badge-offset-y: calc(0px - var(--badge-size) / 3);
  --badge-size: 1.5rem;
  --circle-size: 2rem;
  --dot-offset: 0.5rem;
  --dot-size: 0.5rem;

  --b: initial;
  --bgc: hsl(195, 100%, 30%);
  --bdrs: 0;
  --c: hsl(195, 100%, 99%);
  --d: inline-flex;
  --fz: 0.625rem;
  --fw: 700;
  --h: auto;
  --l: initial;
  --m: 0.4rem;
  --p: 0;
  --pos: static;
  --r: initial;
  --t: initial;
  --tt: uppercase;
  --w: initial;

  position: relative;
}

[data-after-text]:not([data-after-text=""])::after {
  content: attr(data-after-text);
}
[data-before-text]:not([data-before-text=""])::before {
  content: attr(data-before-text);
}

[data-after-text]:not([data-after-text=""])::after,
[data-before-text]:not([data-before-text=""])::before {
  align-items: center;
  background: var(--bgc);
  border-radius: var(--bdrs);
  bottom: var(--b);
  box-shadow: var(--bxsh);
  box-sizing: border-box;
  color: var(--c);
  display: var(--d);
  font-size: var(--fz);
  font-weight: var(--fw);
  height: var(--h);
  justify-content: center;
  left: var(--l);
  padding: var(--p);
  position: var(--pos);
  right: var(--r);
  text-decoration: none;
  text-transform: var(--tt);
  top: var(--t);
  width: var(--w);
}

/* MODIFIERS */
[data-after-type*="badge"]::after,
[data-before-type*="badge"]::before {
  --bdrs: var(--badge-size);
  --bxsh: 0 0 0 2px rgba(255, 255, 255, 0.5);
  --h: var(--badge-size);
  --p: 0;
  --pos: absolute;
  --w: var(--badge-size);
}
[data-after-type*="circle"],
[data-before-type*="circle"] {
  align-items: center;
  display: flex;
}
[data-after-type*="circle"]::after,
[data-before-type*="circle"]::before {
  --bdrs: 50%;
  --fw: 400;
  --h: var(--circle-size);
  --tt: initial;
  --w: var(--circle-size);
}
[data-after-type*="circle"]::after,
[data-after-type*="pill"]::after {
  margin-inline-start: 1ch;
}
[data-before-type*="circle"]::before,
[data-before-type*="dot"]::before,
[data-before-type*="pill"]::before {
  margin-inline-end: 1ch;
}
[data-after-type*="dot"]::after,
[data-before-type*="dot"]::before {
  --bdrs: 50%;
  --d: inline-block;
  --fz: 50%;
  --h: var(--dot-size);
  --p: 0;
  --pos: relative;
  --t: -1px;
  --w: var(--dot-size);
}
[data-after-type*="dot"]::after,
[data-before-type*="dot"]::before {
  content: "" !important;
}
[data-after-type*="pill"]::after,
[data-before-type*="pil"]::before {
  --bdrs: 1rem;
  --p: 0.25rem 0.75rem;
  --pos: relative;
  --t: -1px;
}

/* COLORS */
[data-after-type*="blue"]::after,
[data-before-type*="blue"]::before {
  --bgc: #007acc;
}
[data-after-type*="darkgray"]::after,
[data-before-type*="darkgray"]::before {
  --bgc: #706e6b;
  --c: #fff;
}
[data-after-type*="green"]::after,
[data-before-type*="green"]::before {
  --bgc: #04844b;
}
[data-after-type*="lightgray"]::after,
[data-before-type*="lightgray"]::before {
  --bgc: #ecebea;
  --c: #080707;
}
[data-after-type*="orange"]::after,
[data-before-type*="orange"]::before {
  --bgc: #ffb75d;
  --c: #080707;
}

[data-after-type*="red"]::after,
[data-before-type*="red"]::before {
  --bgc: #c23934;
}

/* POSITION */
[data-after-type*="top"]::after,
[data-before-type*="top"]::before {
  --b: auto;
  --pos: absolute;
  --t: var(--dot-offset);
}
[data-after-type*="right"]::after,
[data-before-type*="right"]::before {
  --l: auto;
  --pos: absolute;
  --r: var(--dot-offset);
}
[data-after-type*="bottom"]::after,
[data-before-type*="bottom"]::before {
  --b: var(--dot-offset);
  --pos: absolute;
  --t: auto;
}
[data-after-type*="left"]::after,
[data-before-type*="left"]::before {
  --pos: absolute;
  --r: auto;
  --l: var(--dot-offset);
}
[data-after-type*="badge"][data-after-type*="top"]::after,
[data-before-type*="badge"][data-before-type*="top"]::before {
  --m: 0;
  --t: var(--badge-offset-y);
}
[data-after-type*="badge"][data-after-type*="right"]::after,
[data-before-type*="badge"][data-before-type*="right"]::before {
  --m: 0;
  --r: var(--badge-offset-x);
}
[data-after-type*="badge"][data-after-type*="bottom"]::after,
[data-before-type*="badge"][data-before-type*="bottom"]::before {
  --b: var(--badge-offset-y);
  --m: 0;
}
[data-after-type*="badge"][data-after-type*="left"]::after,
[data-before-type*="badge"][data-before-type*="left"]::before {
  --l: var(--badge-offset-x);
  --m: 0;
}

::-webkit-input-placeholder {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  padding-right: 0.15em;
  white-space: nowrap;
}

input.mdg-search-input:focus::-webkit-input-placeholder {
  -webkit-transform: translateX(125%);
  font-size: 75%;
  opacity: 0.05;
}

input.mdg-search-input.imitatefocus::-webkit-input-placeholder {
  -webkit-transform: translateX(125%);
  opacity: 0.05;
}

.mdg-historique-table > .kGIWTA {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-historique-table > div > div > div.jNMKxq {
  background-color: transparent !important;
  color: #fff !important;
}
.mdg-historique-table > div > div > div > div.iYWPft {
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-textfield-out-lined > div > div.css-1s2u09g-control {
  background-color: transparent !important;
  color: #fff !important;
}
.mdg-textfield-out-lined > div > div.css-1s2u09g-control:hover {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff;
}

.mdg-textfield-out-lined > div > div.css-2613qy-menu {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}
.mdg-textfield-out-lined > div > div.css-2613qy-menu > div {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}

.mdg-textfield-out-lined > div > div.css-2613qy-menu > div > div {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}

.mdg-textfield-out-lined > div > div.css-2613qy-menu > div > div > div {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}

.mdg-textfield-out-lined > div > div.css-1pahdxg-control {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
  box-shadow: 0 0 0 0 #fff !important;
}

.mdg-form-seller.mdg-textfield-out-lined > div > div.css-1pahdxg-control {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #17A2B8 !important;
  box-shadow: 0 0 0 0 #17A2B8 !important;
}

.mdg-textfield-out-lined > div > div.css-1pahdxg-control:hover {
  border-color: #fff !important;
}

.mdg-form-seller.mdg-textfield-out-lined .css-1pahdxg-control:focus,
.mdg-form-seller.mdg-textfield-out-lined .css-1pahdxg-control:active,
.mdg-form-seller.mdg-textfield-out-lined .css-1pahdxg-control:hover {
  border-color: #17A2B8 !important;
}

.mdg-textfield-out-lined > div > div.css-2613qy-menu {
  border-color: #fff !important;
  background-color: transparent !important;
  color: #fff !important;
}

.mdg-textfield-out-lined > div > div > div > div > input {
  color: #fff !important;
}

a.navbar-brand.ml-2:hover {
  background: transparent !important;
}

/**
start not found
*/

.error-container {
  text-align: center;
  font-size: 180px;
  font-weight: 800;
  margin: 20px 15px;
}
.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #083740;
}
.error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.error-container > span:nth-of-type(1) {
  color: #b1aeae;
  animation: colordancing 4s infinite;
}
.error-container > span:nth-of-type(3) {
  color: #17a2b8;
  animation: colordancing2 4s infinite;
}
.error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  animation: shadowsdancing 4s infinite;
}
.error-container > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}
@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 30px 0 rgba(209, 242, 165, 0.4),
      inset -30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 30px 0 rgba(245, 105, 145, 0.4),
      inset -30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 30px 0 rgba(255, 196, 140, 0.4),
      inset -30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes colordancing {
  0% {
    color: #b1aeae;
  }
  25% {
    color: #083740;
  }
  50% {
    color: #17a2b8;
  }
  75% {
    color: #fff;
  }
  100% {
    color: #b1aeae;
  }
}
@keyframes colordancing2 {
  0% {
    color: #17a2b8;
  }
  25% {
    color: #fff;
  }
  50% {
    color: #b1aeae;
  }
  75% {
    color: #083740;
  }
  100% {
    color: #17a2b8;
  }
}

/* demo stuff */
h1.mdg-tittle-not-found {
  text-align: center;
  margin: 30px 15px;
  color: #fff;
}

h6.mdg-tittle-not-found {
  text-align: center;
  margin: 30px 15px;
  color: #fff;
}

h1.mdg-tittle-not-found-danger {
  text-align: center;
  margin: 30px 15px;
  color: red;
}

h6.mdg-tittle-not-found-danger {
  text-align: center;
  margin: 30px 15px;
  color: red;
}

.zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}

.mdg-link-container {
  text-align: center;
}

a.mdg-more-link {
  text-transform: uppercase;
  font-size: 13px;
  background-color: #92a4ad;
  padding: 10px 15px;
  border-radius: 0;
  color: #416475;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  line-height: 1.5;
  text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}

.mdg-container-notfound {
  position: relative;
  border-radius: 8px;
  height: 410px;
  margin: 13rem auto;
}

/**
end not found
*/

.mdg-card-pack {
  position: relative;
  height: 450px;
  border-radius: 10px;
  background-color: #fff;
}

@keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

@-webkit-keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

@-moz-keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

@-o-keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

.mdg-fo-card .mdg-fo-card-image img:hover {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
}
.mdg-fo-card .mdg-fo-card-image:hover {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
}

/*
  card buy
  */

.section-cards {
  z-index: 3;
  position: relative;
}

.section-gray {
  background: transparent;
  padding: 60px 0 30px 0;
}

/*---------------------------------------------------------------------- /
CARDS
----------------------------------------------------------------------- */

.mdg-fo-card {
  /* border-top: 3px solid #17A2B8; */
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: #fff;
  background-image: linear-gradient(to bottom right, #404950, #171f22);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdg-card-caption {
  color: #fff;
  padding: 5px;
  font-size: 15px;
}

.mdg-fo-card .mdg-fo-card-image {
  background-image: linear-gradient(to bottom right, #404950, #171f22);
  height: 216px;
  position: relative;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdg-fo-card .mdg-fo-card-image img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: contain;
  pointer-events: none;
}

.mdg-fo-card .mdg-fo-card-image .card-caption {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-size: 15px;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}

.mdg-fo-category {
  color: #fff;
  padding: 5px;
}

.mdg-fo-card img {
  width: 100%;
  height: auto;
}

.img-raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdg-fo-card .ftr {
  margin-top: 15px;
}

.mdg-fo-card .ftr div {
  display: inline-block;
}

.mdg-fo-card .ftr .author {
  color: #fff;
}

.mdg-fo-card .ftr .stats {
  float: right;
  line-height: 30px;
}

.mdg-fo-card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
  color: #fff;
}

/** 
end pack card
*/

.mdg-dropdwon-menu-action {
  background-image: linear-gradient(to bottom right, #404950, #171f22);
}
.mdg-color-menu-item {
  color: #fff !important;
}

.badge-warning {
  font-size: 8px !important;
  top: 3px !important;
  position: absolute !important;
  right: 0 !important;
}

.mdg-list-group-item {
  position: relative;
  display: block;
  color: #fff;
  padding: 0.75rem 1.25rem;
  /* background-color: #414c52;
  border: 1px solid #414c52; */
}

.mdg-color-text-meta {
  color: #17a2b8;
}

.mdg-normal-label {
  font-weight: normal !important;
}

.mdg-card-payment {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  color: #fff;
  word-wrap: break-word;
  /* background-color: #414c52; */
  /* background-clip: border-box; */
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.dropdown-item.mdg-color-menu-item:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #181f23;
}

.mdg-width-dropdwon {
  min-width: 50px !important;
}

.swal2-checkbox,
.swal2-radio {
  background: transparent !important;
}

.mdg-text-white {
  color: #fff !important;
  font-weight: normal !important;
}

.mdg-nav-meta.nav-pills .nav-link:hover {
  color: #fff !important;
}

.mdg-nav-meta.nav-pills .nav-link.active {
  color: #fff !important;
}

.mdg-textfield-out-lined > div > .css-2613qy-menu {
  overflow-y: auto !important;
  min-height: 500px !important;
}

.mdg-bg-row {
  background-image: linear-gradient(to bottom right, #404950, #171f22);
  border-radius: 0 20px 0 20px;
}

.mdg-bg-row-proposals {
  background-image: linear-gradient(
    to bottom,
    transparent,
    transparent,
    transparent,
    #404950
  );
  border-radius: 0 20px 0 20px;
}

.mdg-bg-row-3 {
  background-image: linear-gradient(to right, transparent, #404950, #171f22);
  border-radius: 0 20px 0 20px;
}

.mdg-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

a.nav-link.mdg-active {
  color: #fff !important;
  background: #34393c !important;
}

a.mdg-view-detail-sell:hover {
  background: transparent !important;
}

.mdg-disabled-btn {
  background-color: #3c4246 !important;
  color: #000000 !important;
  cursor: no-drop;
}

.mdg-relative {
  position: relative;
}

.mdg-footer-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.mdg-ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.mdg-ribbon-right {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.mdg-ribbon span {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #17a2b8;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}

.mdg-ribbon-right span {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #17a2b8;
  box-shadow: 0 3px 10px -5px rgb(0 0 0);
  position: absolute;
  top: 19px;
  right: -21px;
}

.mdg-ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #17a2b8;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #17a2b8;
}

.mdg-ribbon-right span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #17a2b8;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #17a2b8;
}

.mdg-ribbon-right span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #17a2b8;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #17a2b8;
}

.mdg-count-badge {
  background: #17a2b8;
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
}

.mdg-proposal-box.hide {
  animation: slideInUpAnime 1s !important;
}

.mdg-bg-btn-group {
  background: #414c52;
  color: #fff;
}

.mdg-bg-btn-group .mdg-btn-meta {
  color: #fff;
}

.mdg-card-subscribe {
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #404950, #171f22);
}

.mdg-small {
  font-size: 11px;
}

.mdg-price {
  color: #fff;
  font-size: 30px;
}

.mdg-btn-meta-primary {
  background: #17a2b8;
  color: #fff;
  border: none;
}

.mdg-btn-meta-primary:hover {
  background: #00ccfe;
}

.mdg-btn-meta-yellow {
  background: #b86017;
  color: #fff;
  border: none;
}

.mdg-btn-meta-yellow:hover {
  background: #da8946;
}

.mdg-body-subscribe {
  position: relative;
  padding: 10px 15px;
}

.mdg-header-icon-subscribe {
  background: #080707;
  border-radius: 50px;
  width: 100%;
  z-index: 1;
  position: inherit;
}

.mdg-body-content-subscribe {
  background: #2c2c2c;
  padding: 10px;
  border-radius: 20px;
  margin-top: -20px;
  z-index: 0;
}

.mdg-text-meta-color {
  color: #17a2b8 !important;
}

.ant-progress-bg {
  background-color: #17a2b8 !important;
  height: 4px !important;
}

.ant-progress+.fa-plane {
  position: absolute;
  top: 3px;
  font-size: 25px;
}

.mdg-list-subscribe {
  font-size: 11px;
}

.mdg-e-ticket {
  position: absolute;
  top: -7px;
  right: 4px;
  background: #17a2b8;
  border-radius: 20px;
  padding: 1px 10px;
  font-size: 11px;
}

.mdg-popular {
  border: 1px solid #17a2b8;
}

.slide-down {
  animation: slide-down 0.3s linear both;
}

.slide-up {
  animation: slide-up 0.3s linear both;
}

.mdg-width-info-plane {
  position: absolute;
  z-index: 5;
  width: 350px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
  padding: 10px;
  margin: 20px;
  /* min-height: 500px;
  height: 500px; */
}

.mdg-width-info-cargo {
  position: absolute;
  z-index: 5;
  width: 500px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
  padding: 10px;
  margin: 20px;
  /* min-height: 500px;
  height: 500px; */
}

.mdg-btn-det-plane {
  padding: 5px 20px 5px 5px;
  background: #17A2B8;
  color: #fff;
  border-radius: 0;
}

.mdg-btn-det-plane::after {
  right: 64px;
  display: block;
  position: absolute;
  top: -5px;
  width: 20px;
  height: 28px;
  content: "";
  border-top: solid 17px transparent;
  border-bottom: solid 17px transparent;
  border-right: solid 17px #0D1115;
}

.mdg-card-info-plane {
  border: 1px solid;
  min-height: 500px;
  height: 500px;
  overflow-y: scroll;
  padding: 5px;
}

.mdg-card-info-content-plane {
  padding: 5px;
  border: 1px solid;
  background: linear-gradient(to bottom right, transparent, transparent, transparent, transparent, #17A2B8);
}

.mdg-bl-list {
  padding: 5px;
  border-left: 4px solid #17A2B8; 
  border-radius: 0px 5px 5px 0;
  border-bottom: 0.5px solid #182f32;
  background: linear-gradient(45deg, #1c1c1c, transparent);
  margin-bottom: 20px;
  min-height: 60px;
  height: 60px;
}

.mdg-bl-list:hover {
  background: #182f32;
}

.mdg-bl-liste {
  padding: 5px;
  border-right: 1px solid #17A2B8; 
  border-radius: 5px 0px 0px 5px;
  border-bottom: 0.5px solid #182f32;
  background: linear-gradient(45deg, transparent, #202222);
  margin-bottom: 20px;
  min-height: 60px;
  height: 60px;
}

.mdg-bl-liste:hover {
  background: #182f32;
}

.mdg-title-details-vols {
  box-shadow: 0px 2px 2px 0px #252626;
  padding-bottom: 5px;
}

.mdg-body-details-vols {
  padding: 5px;
  /* border: 1px solid #4f767c; */
}


.mdg-center {
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 4;
}

.mdg-centers-login {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mdg-card-login {
  position: relative;
}

.mdg-card-login {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(12, 18, 23, 0.8);
  padding: 10px;
  border-radius: 15px;
  position: relative;
}

/* .mdg-bg-register .mdg-card-login {
  min-height: 480px;
  height: 480px;
} */

.mdg-footer-register {
  font-size: 14px;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 15px;
}

.mdg-text-white {
  color: #fff !important;
}

.mdg-text-muted {
  color: #aaa5a5;
}

.mdg-header-login {
  margin-bottom: 10px;
}

.mdg-form-control {
  background: transparent !important;
  color: #fff !important;
}

.mdg-form-control::placeholder {
  color: #fff !important;
  background: transparent !important;
}

.ant-input {
  background: transparent !important;
  color: #fff !important;
}

.ant-input::-webkit-input-placeholder,
.ant-input::-moz-placeholder,
.ant-input::placeholder {
  background: transparent !important;
  color: #fff !important;
}

.anticon.ant-input-password-icon,
.anticon.ant-input-password-icon::after,
.anticon.ant-input-password-icon:hover {
  color: #fff !important;
}

.mdg-img-lock-login {
  width: 25%;
}

.mdg-align-center {
  text-align: center;
}

.mdg-btn-common {
  background-color: #5d5f60;
  border-color: #5d5f60;
  color: #fff;
}

.mdg-btn-common:hover {
  background-color: #4f5152;
  border-color: #4f5152;
  color: #fff;
}

.mdg-btn-metaflight {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #fff !important;   
}

.mdg-btn-metaflight:hover {
  background: #1593a7 !important;
  border-color: #1593a7 !important;
  color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.mdg-mt-2 {
  margin-top: 10px !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
}

@keyframes slide-down {
  0% {
    visibility: hidden;
    height: 0;
  }
  95% {
    visibility: visible;
    height: 500px;
  }
  /* Set height to 'auto' after animation for spacing showing form-invalid feedback message */
  100% {
    visibility: visible;
    height: auto;
  }
}
@keyframes slide-up {
  from {
    visibility: visible;
    height: 500px;
  }
  to {
    visibility: hidden;
    height: 0;
  }
}

.mdg-proposal-box {
  max-height: 500px;
  height: 0px;
  overflow: scroll;
}



.mdg-textfield-outlined.registration>div>div.css-1s2u09g-control {
  border-color: #928888 !important;
}


.btn {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  border-radius: 0;
  z-index: 200;
}
.btn:focus, .btn:active, input:focus{
 box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important;
 outline: none;
 
}
.btn-decrement {
 border-bottom-left-radius: 50%;
 border-top-left-radius: 50%;
 border: none;
 margin-right: -2px;
 z-index: 0;
}

.btn-increment {
 border-bottom-right-radius: 50%;
 border-top-right-radius: 50%;
 border: none;
 margin-left: -2px;
 z-index: 0;
}

.spinner-control {
 display: flex;
}

.spinner-control input{
 border: 1px solid #17A2B8;
 background-color: transparent;
 color: #fff;
 text-align: center;
 /* z-index: 100; */
 width: 100%; cursor: default;
 height: 45px;
}

.spinner-control input.mdg-up-categorie {
 border: 1px solid #6c757d !important;
 background-color: transparent;
 color: #fff;
 text-align: center;
 width: 100%; cursor: default;
 height: 30px;
}

.btn-muted {
  background: #6c757d!important;
  color: #fff !important;
}

.form-spinner label{
 width: 100%;
 text-align: center;
}

.mdg-date-picker input {
  color: #fff;
}

.mdg-date-picker-dark input {
  color: #928888;
}

.mdg-form-seller .mdg-date-picker-dark input {
  color: #fff !important;
}

.mdg-form-seller .mdg-date-picker-dark span {
  color: #fff !important;
}

.mdg-form-seller .mdg-date-picker-dark input::placeholder {
  color: #fff !important;
}

.mdg-date-picker>div {
  border-radius: 5px;
  height: 40px;
}

.mdg-date-picker-dark>div {
  border-radius: 5px;
  height: 40px;
}

.mdg-date-picker {
  max-width: 100%;
  text-align: center;  
  width: 350px !important;
}

.mdg-date-picker-dark {
  max-width: 100%;
  text-align: center;  
  width: 350px !important;
}

.mdg-form-seller .mdg-date-picker-dark {
  max-width: 100%;
  text-align: center;  
  width: 100% !important;
}


.mdg-date-picker svg {
  stroke: #fff!important;
}

.mdg-date-picker-dark svg {
  stroke: #928888!important;
}

.mdg-form-seller .mdg-date-picker-dark svg {
  stroke: #fff !important;
}

.mdg-date-picker .react-calendar {
  width: 350px !important;
  border: none !important;
  font-family: "Ubuntu" !important;
  padding: 5px;
}

.mdg-date-picker-dark .react-calendar {
  width: 350px !important;
  border: none !important;
  font-family: "Ubuntu" !important;
  padding: 5px;
}

.mdg-date-picker-dark .react-date-picker__wrapper {
  border-color: transparent #92969a #92969a;
}

.mdg-form-seller .mdg-date-picker-dark .react-date-picker__wrapper {
  border-color: #17A2B8;
}


.mdg-date-picker-dark .react-date-picker__calendar {
  width: 210px;
  border: 1px solid #e1e0e0;
}

.mdg-date-picker-dark .react-calendar__navigation button {
  min-width: 22px !important;
}




.mdg-top-7 {
  margin-top: 7px;
}

.mdg-text-seller-meta {
  color: #17A2B8 !important;
  font-weight: normal !important;
}

.mdg-seller-type .css-1s2u09g-control{
  border-color: #17A2B8 !important;
}


.mdg-text-label {
  color: #92969a;
  font-weight: normal !important;
}


.mdg-d-box-shadow {
  box-shadow: none !important;
}






.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #17A2B8;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 92%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}


.mdg-search-place button.mdg-btn-search-meta {
  position: absolute;
  right: 0;
  width: 30px;
  border: none;
  height: 100%;
  color: #fff;
  background: #17A2B8
}
.mdg-search-place button.mdg-btn-search-meta:hover {
  background: #138799
}

.mdg-fil-arian {
  color: #fff !important;
  font-size: 12px !important;
}

.mdg-fil-arian:hover {
  text-decoration: underline !important;
}

.mdg-fil-arian.active {
  color: #007acc;
}




.mdg-ariane-breadcrumb {
	display: inline-block;
	box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.35);
	overflow: hidden;
	border-radius: 5px;
	counter-reset: flag; 
}

.mdg-ariane-breadcrumb a {
	text-decoration: none;
	outline: none;
	display: block;
	float: left;
	font-size: 12px;
	line-height: 36px;
	color: white;
	padding: 0 10px 0 60px;
	background: #414C52;
	background: linear-gradient(to right, transparent, #414C52);
	position: relative;
}

.mdg-ariane-breadcrumb a:first-child {
	padding-left: 46px;
	border-radius: 5px 0 0 5px; 
}

.mdg-ariane-breadcrumb a:first-child:before {
	left: 14px;
  text-align: center;
}

.mdg-ariane-breadcrumb a:before {
	left: 14px;
  text-align: center;
}
.mdg-ariane-breadcrumb a:last-child {
	border-radius: 0 5px 5px 0; 
	padding-right: 20px;
  text-align: center;
}

.mdg-ariane-breadcrumb a.active, .mdg-ariane-breadcrumb a:hover{
	background: #414C52;
	background: linear-gradient(to right, transparent,#414C52)
}

.mdg-ariane-breadcrumb a.active:after, .mdg-ariane-breadcrumb a:hover:after {
	background: #414C52;
}

/* .mdg-ariane-breadcrumb a:hover:after {
	background: #17A2B8;
} */

/* .mdg-ariane-breadcrumb a:hover {
  background: #414C52;
  background: linear-gradient(to right, transparent,#17A2B8);
} */


/* .mdg-ariane-breadcrumb a.active:hover, .mdg-ariane-breadcrumb a:hover {
  background: #17A2B8;
  background: linear-gradient(to right, transparent,#17A2B8);
} */



.mdg-ariane-breadcrumb a.active:last-child, .mdg-ariane-breadcrumb a:hover:last-child {
	background: #17A2B8;
  background: linear-gradient(to right, transparent,#17A2B8);
}

.mdg-ariane-breadcrumb a:last-child {
	background: #17A2B8;
  background: linear-gradient(to right, transparent,#17A2B8);
}
 
.mdg-ariane-breadcrumb a:after {
	content: '';
	position: absolute;
	top: 0; 
	right: -18px;
	width: 36px; 
	height: 36px;
	transform: scale(0.707) rotate(45deg);
	z-index: 1;
	background: #414C52;
	box-shadow: 
		2px -2px 0 2px #17A2B8, 
		3px -3px 0 2px rgba(255, 255, 255, 0.1);
	border-radius: 0 5px 0 50px;
}

.mdg-ariane-breadcrumb a:last-child:after {
	content: none;
}

.mdg-ariane-breadcrumb a:before {
	border-radius: 100%;
	width: 20px;
	height: 20px;
	line-height: 20px;
	margin: 8px 0;
	position: absolute;
	top: 0;
	left: 30px;
	background: #444;
	background: linear-gradient(#444, #222);
	font-weight: bold;
}


.mdg-ul-height {
  min-height: 500px;
}

.mdg-center {
  width: 100%;
  z-index: 100000;
  height: 100%;
  margin: 0;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 100;
}

.mdg-centers {
  width: 35%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.mdg-card-header-registration {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
}

.mdg-card-footer-registration {
  padding: 0.75rem 1.25rem;
  background-color: transparent;
}


.mdg-bg-white {
  background: #fff;
}

.mdg-bg-meta {
  background: #01C4F4;
}


.mdg-aligns-justify-center {
  justify-content: center;
}


.mdg-border-right-top-end {
  border-radius: 0 10px 10px 0;
}

.mdg-border-left-top-end {
  border-radius: 10px 0 0 10px;
}

.mdg-border-none {
  border: none !important;
  box-shadow: none !important;
}

.mdg-text-registration {
  color: #92969a;
}


.mdg-height-step-registration {
  min-height: 330px !important;
}


.mdg-border-refine {
  padding: 10px;
  border-top: 2px solid #17A2B8;
  border-radius: 5px;
}

.mdg-date-picker::placeholder {
  color: #fff;
}

.mdg-date-picker {
  color: #fff !important;
  text-align: left !important;
}


.mdg-change-bg-datepicker .react-datepicker__header {
  background-color: #17A2B8 !important;
}

.mdg-change-bg-datepicker .react-datepicker {
  background-color: #fff !important;
  backdrop-filter: blur(5px) !important;
}

.mdg-change-bg-datepicker .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #fff;
}

.mdg-change-bg-datepicker select {
  color: #083740 !important;
  border-color: transparent #083740 #083740 !important;
}

.react-datepicker__day {
  color: #181F23 !important;
  font-weight: bold;
}

.react-datepicker__day:hover {
  color: #17A2B8 !important;
}


.react-datepicker__triangle::after {
  border-bottom-color: #17A2B8 !important;
}

.react-datepicker__day--disabled {
  color: #6a6868 !important;
}


.react-datepicker__day--keyboard-selected{
  background-color: #17A2B8 !important;
  color: #fff !important;
}

.mdg-height-select-profil .css-1insrsq-control {
  height: 45px !important;
}

.mdg-height-select-profil .css-1s2u09g-control{
  height: 42px;
}

.mdg-textfield-out-lined .mdg-date-picker input {
  border-color: #fff !important;
  height: 45px !important;
}


.btn-muet {
  padding: 5px;
  background: #6c757d!important;
  color: #fff !important;
}



.mdg-not-connected,
.mdg-not-connected:hover,
.mdg-not-connected:active {
  padding: 10px !important;
}

.mdg-not-connected.active {
  background: #181f23;
}



.mdg-d-flex {
  display: flex;
}

.mdg-shopping {
  z-index: 222222222222;
  position: fixed;
  right: 50px;
  bottom: 50px;
}


.mdg-width-panier-all {
  max-width: 450px;
  min-width: 450px;
  height: 90%;
  position: fixed;
  z-index: 555555555555555555;
  border-radius:2px 20px 2px 20px;
  right: 50px;
  top: 80px;
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
  box-shadow: 1px 0px 7px 1px #2d5162;
}

.mdg-t-10 {
  margin-top: 150px;
}

.mdg-force-left {
  margin-left: -25px !important;
}

.mdg-btn-transparent {
  background: transparent;
  color: #fff !important;
  box-shadow: none;
  border: none;
}

.mdg-height-panier-cart-parent .mdg-height-cart-panier {
  max-height: 250px;
  overflow-y: auto;
}

.mdg-height-panier-cart-parent .mdg-height-cart-panier::-webkit-scrollbar {
  display: block;
}

.mdg-height-moyen-payment {
  height: 180px;
}

.mdg-height-panier-cart-parent .mdg-height-cart-panier::-webkit-scrollbar-thumb {
  background: #17A2B8;
}


.visible-in-right {
  animation: movein 0.5s ease forwards;
}

.visible-out-right {
  animation: moveout 0.5s ease forwards;
}

.visible-in-show {
  animation: showIn 0.5s ease forwards;
}

.visible-out-show {
  animation: showOut 0.5s ease forwards;
}


@keyframes movein {
  from { right: -600px; }
  to   { right: 50px; }
}

@keyframes moveout {
  from { right: 10px; }
  to   { right: -600px; }
}

@keyframes showIn {
  from { right: 0px; }
  to   { right: 50px; }
}

@keyframes showOut {
  from { right: 50px; }
  to   { right: 0px; }
}

.mdg-text-privacy {
  font-size: 12px;
  color: #fff;
}

.mdg-text-privacy  label{
  font-weight: inherit !important;
}
.mdg-text-privacy  input{
  margin-top: 3px;
}
.mdg-count-panier {
  position: absolute;
  top: -10px;
  right: 5px;
  background: red;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.mdg-panier-bottom {
  position: absolute;
  bottom: 0px;
  width: 96%;
}

@media only screen and (max-width: 767px) { 
  
  .mdg-width-panier-all {
    max-width: 400px;
    min-width: 400px;
  } 

  .mdg-min-h-panier {
    min-width: 120px;
  }
  
  .mdg-width-info-plane {
    width: 250px;
  }


  @keyframes movein {
    from { right: -600px; }
    to   { right: 6px; }
  }

  @keyframes moveout {
    from { right: 6px; }
    to   { right: -600px; }
  }

  .mdg-list-group-item {
    padding: 5px 5px;
  }
}


.nav-item.dropdown.mdg-notif {
  width: 50px !important;
  display: flex !important;
  justify-content: center !important;
}

.mdg-badge-notif {
  position: absolute;
  background: #17A2B8;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
  top: 7px;
  text-align: center;
  justify-content: center;
  align-items: center;
}


.mdg-img-shop {
  width: 40px;
  position: absolute;
  left: 4px;
  top: 8px;
}

.mdg-pos-relative {
  position: relative;
}

.mdg-pos-relative .fa-camera{
  position: absolute;
  bottom: 12px;
  right: 45%;
  left: 55%;
  font-size: 20px;
  cursor: pointer;
}

.mdg-border-end {
  border-style: double;
  border-bottom: 1px;
  border-left: 1px;
  border-right: 1px;
}
.mdg-border-dashed {
  border-top: 1px dashed
}

.mdg-mt-6 {
  margin-top: 30px;
}

#id-bid-date-limit{
  width: 100% !important;
}

.mdg-temp-checkout input {
  border: 1px solid white !important;
  padding: 5px 5px !important;
  border-radius: 5px !important;
}

input.InputElement.is-empty.Input.Input--empty {
  border: 1px solid white !important;
  padding: 5px 5px !important;
  border-radius: 5px !important;
}

.InputContainer input.InputElement {
  border: 1px solid white !important;
  padding: 5px !important;
  border-radius: 5px !important;
  height: 24px !important;
}

.InputContainer .InputElement {
  border: 1px solid white !important;
  padding: 5px !important;
  border-radius: 5px !important;
  height: 24px !important;
}

.ElementsApp input {
  border-bottom: 1px solid !important;
}

.mdg-border-checkout {
  border: 1px solid #fff;
  border-radius: 5px;
  height: 40px;
}

.mdg-card-box-notice {
  position: relative;
  height: 350px;
  cursor: pointer;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #404950, #171f22);
}

.mdg-card-box-notice:hover {
  transform: scale(1.03, 1.03);
}

.mdg-content-notice {
  position: relative;
  background: linear-gradient(to bottom, transparent, #000000);
  width: 100%;
  height: calc(350px - 250px);  
  border-radius: 0 0 10px 10px;
}

.mdb-bottom-notice {
  position: absolute;
  bottom: 5px;
  left: 0;
  font-size: 13px;
  padding: 10px;
  color: #fff;
  width: 100%;
}

.mdg-name-notice {
  background: #17A2B8;
  color: #fff;
  padding: 5px;
  position: absolute;
  top: -15px;
}

.mdg-description-notice {
  position: absolute;
  margin-top: 30px;
  text-align: justify;
  padding: 5px;
  color: #fff;
  width: 100%;
  font-size: 14px;
}



.mdg-notice-image-sm {
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  object-fit: fill;
}

.mdg-notice-image-sm-2 {
  width: 100%;
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
}

.mdg-notice-image {
  width: 100%;
  object-fit: fill;
  height: auto;
}

.mdg-card-meta-modal {
  background-color: RGBA(12, 18, 23, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 8888;
  overflow-y: auto;
}

.mdg-content-meta-modal {
  width: 40%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  border-radius: 10px;
  padding: 5px 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
  box-shadow: 1px 0px 7px 1px #323638;
} 

.mdg-p-modal {
  position: relative;
}

.mdg-header-content-modal {
  position: absolute;
  top: -30px;
  left: -30px;
}

.mdg-title-modal {
  padding: 10px 20px;
}

.mdg-body-modal {
  position: relative;
  padding: 10px 20px;
}

.mdg-payment-logo {
  width: 50px;
}

.mdg-btn-transparent.active {
  background: #17a2b8 !important;
}

.mdg-min-details {
  min-height: 380px;
}

.mdg-text-success {
  color: #04844b;
}

.mdg-bg-list {
  background-image: linear-gradient(to right, transparent, transparent , #363a3a);
}

.mdg-list-feature table th.mdg-th-transparent {
  border: none;
  background: transparent;
}

.mdg-list-feature table th,
.mdg-list-feature table thead td.not-active {
  border: none;
}

.mdg-list-feature table td {
  border: 1px solid #3e3c3c;
}

.mdg-list-feature table th.active {
  border: none;
  background: #17A2B8;
  border-radius: 20px 20px 0 0;
}

.mdg-list-feature table th.mdg-not-active {
  border: none;
  background: #080707;
  border-radius: 20px 20px 0 0;
}

.mdg-top-border {
  border-top: 1px solid #fff;
}

.mdg-icon-copy-link {
  position: absolute;
  right: 10px;
  top: 19px;
  cursor: pointer;
}

.mdg-full-width {
  width: 100%;
}

.mdg-list-payement {
  font-size: 12px;
}

.mdg-flag {
  width: 20px;
}

.dropdown-toggle.mdg-notif-icon::after {
  border-top: 0.3em solid transparent !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: transparent !important;
}

.mdg-name-feature {
  width: 60% !important;
  max-width: 60% !important;
  min-width: 60% !important;
}

.mdg-cursor {  
  cursor: pointer;
}

.mdg-cursor:hover {  
  background: #17A2B8;
}

.mdg-misison-image {
  width: 50%;
  object-fit: fill;
  height: auto;
}

.mdg-view-stat {
  position: absolute;
  right: 10px;
}

.mdg-btn-transparent-detail {
  border: 1px solid #858080;
  background: transparent;
  color: #fff !important;
  box-shadow: none;
}

.mdg-btn-transparent-detail.active {
  border: 1px solid #17A2B8;
  background: #17A2B8;
  color: #fff !important;
}

.mdg-content-meta-modal-mission {
  width: 40%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
  box-shadow: 1px 0px 7px 1px #323638;
}

.mdg-misison-image-mission {
  width: 100%;
  object-fit: fill;
  height: auto;
}

.ant-switch-checked:focus {
  box-shadow: none !important;
}

.ant-switch-checked {
  background: #17a2b8 !important;
}

.mdg-12-text {
  font-size: 12px;
}

.mdg-30-top {
  margin-top: -40px;
}

.mdg-card-setting.visible-in-right-menu{
  margin-right: -40px !important;
}

.mdg-details-missions {
  position: absolute;
  z-index: 5;
  top: 100px;
  width: 100%;
}

.mdg-text-sombre {
  color: #181f23;
}

.mdg-bg-nav-missions {
  background: #fff;
  width: 100%;
  margin: 3px;
  color: #181f23;
  padding: 3px;
}

.mdg-bg-nav-missions.active {
  background: #17a2b8;
  width: 100%;
  color: #fff;
  margin: 3px;
  padding: 3px;
}

.mdg-body-missions {
  position: absolute;
  width: 100%;
  height: 400px;
}

.mdg-img-details-missions {
  height: 100%;
  width: 100%;
  object-fit: fill;
}


.mdg-body-details-missions {
  width: 100%;
  position: absolute;
  top: 398px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
}

.mdg-btn-default-missions {
  background: #fff !important;
  color: #000000 !important;
}

/* .mdg-list-feature table td.active {
  border-top-color: rgb(23, 162, 184) !important;
  border-top-style: solid !important;
  border-top-width: 0px !important;
  border-right-color: rgb(23, 162, 184) !important;
  border-right-style: solid !important;
  border-right-width: 1px !important;
  border-bottom-color: rgb(23, 162, 184) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px;
  border-left-color: rgb(23, 162, 184) !important;
  border-left-style: solid !important;
  border-left-width: 1px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
} */

.mdg-list-feature table td.active:last-child {  
  /* border-bottom-color: rgb(23, 162, 184) !important; */
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
}

.mdg-list-feature {
  border-radius: 10px;
  padding: 20px;
  background: #081217;
  /* background-image: linear-gradient(to bottom right, #404950, #171f22); */
} 

.mdg-uppercase {
  text-transform: uppercase;
}

.mdg-incline-image {
  transform: rotate(45deg);
}


.mdg-incline-images {
  transform: rotate(90deg);
  position: absolute;
  top: -14px;
  width: 30px;
}
/* .mdg-list-feature table td.active {
  border-left: #17A2B8 !important;
  border-right: #17A2B8 !important;
  border: 1px solid !important;
} */

.mdg-card-dashboard {
  padding: 10px;
  border-radius: 10px;
  background: #404950;
  width: 100%;
}

.mdg-card-dashboard:hover {
  box-shadow: 1px 0px 11px #17a2b8;
}

.mdg-card-dahsboard-notice {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  background: #081217;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mdg-card-dahsboard-notice-items {
  border-radius: 10px;
  width: 100%;
  position: relative;
  height: 450px;
  background: #081217;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mdg-card-dahsboard-notices {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.mdg-card-dahsboard-flight {
  background-image: linear-gradient(to right, #081217, #081217, #081217);
  border-radius: 0 20px 0 20px;
  padding: 0 10px;
}
.mdg-card-dahsboard-checkout {
  background-image: linear-gradient(to right, #081217, #081217, #081217);
  padding: 10px;
  display: block;
  position: relative;
  margin: auto;
}

.mdg-card-dahsboard-notice:hover {
  box-shadow: rgba(23, 162, 184, 0.35) 0px 5px 15px;
}

.mdg-bg-fond-coin {
  background: url("../../../public/assets/img/fond-coin.png") no-repeat center
  center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat : repeat-x, no-repeat, repeat ;
}

button.mdg-btn-common.active {
  background: #17A2B8 !important;
}

table th.mdg-not-active {
  border: 1px solid #17A2B8;
  border-radius: 20px 20px 0 0 !important;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: transparent;
  color: white;
  font-weight: normal;
}

.mdg-card-paypal .card-field {
  height: 35px;
  padding: 5px;
  margin: 3px;
  color: #fff !important;
}

.mdg-p-dropdown {
  padding: 5px 5px 5px 0px !important;
}

.mdg-card-paypal label{
  display: none !important;
}

.mdg-card-paypal input,
.mdg-card-paypal .card-field input,
.mdg-card-paypal .card-field body form,
.mdg-card-paypal .card-field body {
  color: #fff !important;
}

.mdg-text-green {
  color:green;
}

.mdg-text-red {
  color:red;
}

.mdg-text-blue {
  color: #007bff;
}

.mdg-text-dark-gray {
  color:#343a40;
}

.dropdown-toggle::after {
  vertical-align: 0 !important;
}

@media (min-width: 767px) and (max-width: 1460px) {

  .mdg-list-group-item {
    padding: 0.15rem 0.25rem !important;
  }

  .mdg-height-panier-cart-parent .mdg-height-cart-panier {
    max-height: 80px !important;
  }

  .mdg-card-payment.p-2 {
    padding: 0px !important;
  }

  .mdg-card-payment.mb-3 {
    margin-bottom: 0px !important;
  }

  .mdg-shopping {
    right: 30px !important;
    bottom: 30px !important;
  }

  .mdg-width-panier-all {
    height: 87% !important;
    top: 75px !important;
  }

  .vertical {
    border-left: 1px solid white;
  }
}







.mdg-icon-menu-right {
  background: #081217;
  z-index: 999999999999999999999999999999;
  padding: 10px;
}

.mdg-d-none {
  display: none !important;
}

.mdg-card-setting {
  max-width: 250px;
  min-width: 250px;
  height: auto;
  position: fixed;
  z-index: 3;
  border-radius: 5px;
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
}

.visible-in-right-menu {
  animation: moveine 0.5s ease forwards;
}

.visible-out-right-menu {
  animation: moveoute 0.5s ease forwards;
}

@keyframes moveine {
  from {
    right: -250px;
  }
  to {
    right: 50px;
  }
}

@keyframes moveoute {
  from {
    right: 10px;
  }
  to {
    right: -250px;
  }
}

.mdg-wrap {
  width: 100%;
  height: 100%;
  z-index: 3;
}

.mdg-label-vertical {
  background: #081217;
  font-size: 17px;
  padding: 4px 15px;
  cursor: pointer;
}

.top-right {
  transform: rotate(270deg) scaleX(-1);
  transform-origin: right;

  
}

.top-right .mdg-label-content {
  transform: scaleX(-1);
}

.mdg-btn-transparent {
  border: none !important;
  background: transparent !important;
  color: #fff;
  width: 100%;
}

.mdg-btn-transparent:hover,
.mdg-btn-transparent:active {
  border: none !important;
  box-shadow: none !important;
}

button > div {
  position: fixed;
}

.mdg-content-text {
  padding: 10px;
}

.mdg-on-active {
  width: 100%;
  border: 1px solid #17a2b8;
  margin-top: 4px;
  padding: 3px;
  background: #17a2;
  align-items: center;
  display: flex;
  border-radius: 5px;
}
.mdg-off-active {
  width: 100%;
  border: 1px solid #393c3e;
  margin-top: 4px;
  padding: 3px;
  align-items: center;
  display: flex;
  border-radius: 5px;
}

.mdg-setting-title {
  text-transform: uppercase;
  font-size: 14px;
}

.mdg-list-item {
  margin-top: 10px;
}

.mdg-pos-absolute {
  position: absolute;
  right: 0;
  top: 0;
}

.mdg-fix-btn {
  position: absolute;
  right: 132px !important;
  transform: rotate(270deg) scale(1);
  transform-origin: top right;
  margin-right: 40px;
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
  border: none !important;
}

.mdg-fix-btn:active,
.mdg-fix-btn:focus,
.mdg-fix-btn:hover {
  backdrop-filter: blur(5px);
  background-color: RGBA(12, 18, 23, 0.8);
  border: none !important;
  box-shadow: none !important;
}


.mdg-btn-info-home {
  background: #0C1014 !important;
  color: #fff !important;
}

.mdg-btn-info-home:hover {
  background: #0C1014 !important;
}

.mdg-list-cargo {
  padding: 5px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mdg-list-cargo::-webkit-scrollbar {
  background: #242a2b !important;
  width: 5px;
}

.mdg-img-cargotype {
  height: 25px;
  width: 30px;
  margin-top: 5px;
  transform: rotate(45deg);
}

.mdg-l-cargo {
  border-bottom: 1px solid #605d5d;
  border-bottom-style: dashed;
  margin-bottom: 10px;
}

.mdg-space-12 {
  border-bottom: 1px solid #6a6262;
  margin-bottom: 20px;
}

.mdg-content-progress {
  position: relative;
  width: 100%;
  display: inline-block;
  height: 4px;
  background-color: #fff;
  z-index: 0;
}

.mdg-bar-progress {
  position: absolute;
  background: #17a2b8;
  height: 4px;
  z-index: 1;
}
.mdg-blur-new {
  display: block;
  position: relative;
  margin: auto;
}

.mdg-history-content-new {
  padding: 20px;
}

.mdg-historique-table-new {
  border: 1px solid #2c2c2c;
  padding: 10px;
}

.mdg-show-number-card {    
  letter-spacing: 0.4rem;
  text-align: left;
}

.mdg-card-l-stripe {
  width: 100%;
  background: #464b4c;
  border-radius: 10px;
}
.btn.mdg-btn-transparent.btn-sm:focus{
  box-shadow: none !important;
}

.ant-table {
  background: transparent !important;
  color: #fff !important;
}

.ant-table-sticky-holder {  
  background: transparent !important;
  color: #fff !important;
}

.ant-pagination-item-active {
  background: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}

.ant-table-thead > tr > th {
  background: transparent !important;
  color: #fff !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #4b4a4dc7 !important;
}

.anticon {
  color: #fff !important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  background-color: #7b7070 !important;
}

td.ant-table-column-sort {
  background: transparent !important;
}

.ant-pagination-item {
  background: #4b4a4dc7 !important;
}

span span {
  color: #343A40 !important;
}

.ant-picker-panel-container {
  background: #343A40 !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #636a6e !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #636a6e !important;
}
.ant-picker {
  background: transparent !important;
}
.ant-picker-focused {
  border-color: #fff !important;
}
.ant-picker.ant-picker-range{
  height: 39px !important;
  border-radius: 5px !important;
}
.ant-picker:hover, .ant-picker-focused {
  border-color: #fff !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #17A2B8 !important;
}
.ant-btn.ant-btn-primary.ant-btn-sm{
  background: #17A2B8 !important;  
}

.mdg-use-language span {
  color: #fff !important;
}

/* for tablette */

@media only screen and (max-height: 720px) {
  .mdg-width-card-item {
    padding-bottom: 100px !important;
  }
}

.mdg-aligns-center {
  display: flex;
  align-items: center;
}

.mdg-menu-filter-dashboard {
  width: 100%;
  /* position: relative;
  display: inline-flex;
  vertical-align: middle; */
  background: #17A2B8;
  padding: 5px;
  border-radius: 10px;
}

.mdg-btn-fl-mission.btn.btn-info.mdg-btn-12:hover {
  background: #081217 !important;
  border-radius: 10px !important;
}

.mdg-btn-fl-mission.btn.btn-info.mdg-btn-12.active {
  background: #081217 !important;
  border-radius: 10px !important;
}

.mdg-body-list-filter-mission {
  width: 100%;
  display: inline-block;
}

.mdg-ls-plane {
  color: #17A2B8;
}

.mdg-list-m {
  border: 1px solid #17A2B8;
  padding: 10px;
  border-radius: 10px;
  transform: scale(1.2);
}

.mdg-list-m:hover .list .mdg-text-meta-color {
  color: #fff !important;
}

.mdg-list-m:hover {
  background: #17A2B8;
}

.mdg-list-m.mt-3:hover {
  cursor: url("../../../public/assets/image/cursor_hover.png"), auto;
}
.mdg-card-dahsboard-notice.mdg-cursors:hover {
  cursor: url("../../../public/assets/image/cursor_hover.png"), auto;
}

.mdg-focus-icon {
  border-radius: 50%;
  border: 1px solid;
  padding: 10px;
}

.mdg-focus-icon i.fa{
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  padding-left: 3px;
}

button.btn-icon {
  padding: 17px;
  background: transparent !important;
  border: none;
  text-align: left;
}

.menu-left.nav.flex-column.nav-pills {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: RGBA(8, 18, 23, 0.3);
  padding: 0 10px;
}

.menu-left.nav.flex-column.nav-pills button:hover {
  cursor: url("../../../public/assets/image/cursor_hover.png"), auto;
}

.col-4.list .mdg-text-meta-color {
  font-size: 12px;
}

.menu-left .btn-icon.active {
  transform: scale(1.1) !important;
}

.menu-left .btn-icon:hover span,
.menu-left .btn-icon.active span {
  margin-left: 12px;
}

.menu-left .btn-icon:hover {
  transform: scale(1.1);
}

.mdg-ls-carrer-level>li:first-child {
  color: red;
}

.mdg-ls-cl tr td {
  width: 50%;
  border: none;
  text-align: center;
}

.mdg-rotate-90 {
  transform: rotate(180deg);
}

/* .mdg-ls-cl > tr > td.mdg-first-column {
  background-image: linear-gradient(to bottom, #17A2B8, #0381A2, #054F64, #07222B);
} */

table.mdg-ls-carrer-l {
  border-collapse: separate !important;
  border-spacing: 0;
  text-align: center;
}

td {
  border: solid 1px #000;
  border-style: solid;
  padding: 10px;
}

tr:first-child td:first-child.mdg-cl-first { 
  border-top-left-radius: 50px;
  border: 3px solid;
  border-bottom: 1px;
  border-color: #fff;
}

tr:first-child td:first-child.mdg-cl-first { border-top-right-radius: 50px; }

tr:last-child td:first-child.mdg-cl-last { 
  border-bottom-left-radius: 50px;
  border: 3px solid;
  border-top: 1px;
  border-color: #fff;
}

tr>td.mdg-cl-other {
  border-left: 3px solid;
  border-right: 3px solid;
  border-color: #fff;
  border-top: 1px solid;
}

tr:last-child td:first-child.mdg-cl-last { border-bottom-right-radius: 50px; }


tr:first-child td.mdg-cl-first { border-top-style: solid; }
tr td:first-child.mdg-cl-first { border-left-style: solid; }


.mdg-ls-cl > tbody > tr > td.mdg-max-ls {
  border-radius: 50px 50px 0 0;
}

tr:first-child>td:first-child.mdg-max-ls { 
  border-top-left-radius: 50px;
}

tr:first-child>td:first-child.mdg-max-ls { 
  border-top-right-radius: 50px;
  width: 40%;
}

tr:first-child td.mdg-max-ls { border-top-style: solid; }
tr td:first-child.andrana { border-left-style: solid; }

.mdg-ls-cl > tbody > tr > td.mdg-min-ls {
  border-radius: 0 0 50px 50px;
}

.mdg-ls-carrer-l > tr td.check,
.mdg-ls-cl > tr td.check {
  color: #00CCFF;
}

.mdg-ls-cl > tbody > tr:hover td {
  /* font-size: 18px; */
  color: #00CCFF;
  cursor: url("../../../public/assets/image/cursor_hover.png"), auto;
}

/* .mdg-ls-carrer-l > tr>td:first-of-type {
  background-image: linear-gradient(to bottom, #17A2B8, #17A2B8, #07222B);
} */

td.l10 {
  background-image: linear-gradient(to bottom, #17A2B8, #17A2B8);
}

td.l9 {
  background: #17A2B8;
}

td.l8 {
  background-image: linear-gradient(to bottom, #17A2B8, #0381A2);
}

td.l7 {
  background: #0381A2;
}

td.l6 {
  background-image: linear-gradient(to bottom, #0381A2, #07647c);
}

/* td.l5 {
  background: #07647c;
} */


td.l5 {
  background-image: linear-gradient(to bottom, #00ccff, #00ccff, #028FB3, #03718D);
}

/* td.l5 {
  background-image: linear-gradient(to bottom, #17A2B8, #028FB3, #028FB3, #054B5E);
} */

td.l4 {
  background: #04617A;
}

td.l3 {
  background: #054F64;
}

td.l2 {
  background-image: linear-gradient(to bottom, #04617A, #054455, #054455, transparent);
}
td.l1 {
  background: #07222B;
}

td.l0 {
  background: #07222B;
}

.mdg-height-m {
  min-height: 400px;
  height: 400px;
  overflow-y: scroll;
  padding: 5px 70px;
}

.mdg-card-dashboard-v2 {
  padding: 10px;
  border-radius: 10px;
  background: #2f363a;
  width: 100%;
}

.mdg-height-m::-webkit-scrollbar {
  border-radius: 10px;
  scrollbar-width: thin;
  width: 12px;
}

.mdg-height-m::-webkit-scrollbar {
  border-radius: 10px;
  scrollbar-width: thin;
}

.mdg-height-m::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  scrollbar-width: thin;
}

.mdg-top-0 {
  margin-top: 0;
}

.sc-ezWOiH.jMMJgk {
  background: #fff!important;
}

.__react_component_tooltip.t4a3e5307-4f18-42d8-86ab-38256a9ed5af.place-top.type-dark {
  background: #081217 !important;
}

.bg-tooltip {
  background: #081217 !important;
}

.mdg-list-feature table thead tr th {
  background: #17A2B8;
}

.mdg-list-feature table thead tr th:first-child {  
  border-radius: 20px 0 0 0;
}

.mdg-list-feature table tbody td {
  background-image: linear-gradient(to bottom,#071E26, #081217, #09161D);
}

.fa-angle-up + .mdg-animate-down {
  animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 4s;	
	-webkit-animation-duration: 4s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;				
}



.visible-down {
  animation: udown 0.5s ease forwards;
  position: relative;
}

.visible-up {
  animation: up 0.5s ease forwards;
  position: relative;
}

@keyframes udown {
from {top: 0px;}
	to {top: 5px;}	
}

@keyframes up {
from {top: 5px;}
	to {display: none;}	
}

.mdg-bg-titleinformation {
  padding: 10px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #1C92AD, #17A2B8)
}

.mdg-bg-content-v2 {
  background: #081217;
  border-radius: 10px;
  height: 695px;
  min-height: 695px;
  overflow-y: scroll;
}

.mdg-bg-content-v2::-webkit-scrollbar {
  /* background: #063846; */
  padding: 10px;
  width: 10px;
  display: block !important;
}

.mdg-bg-content-v2::-webkit-scrollbar-thumb {
  background: #17a2b8;
  width: 5px;
  border-radius: 8px;
  margin: 5px;
  padding: 5px;
}

.mdg-tbl-ant thead.ant-table-thead {
  background: #17A2B8 !important;
}

.mdg-tbl-ant thead.ant-table-thead th {
  border: none !important
}

.mdg-tbl-ant .ant-table-tbody > tr > td {
  border: 0px solid !important;
}



ul.andrana {  
  background-image: linear-gradient(to bottom, #00ccff, #028FB3, #04617A, #054455, transparent);
  border-radius: 50px;
  width: 100%;
  border: 3px solid #fff;
  height: 100%;
  align-items: center;
  display: grid;
}
ul.andrana2 {  
  width: 100%;
  height: 100%;
  align-items: center;
  display: grid;
}

ul.andrana li {
  text-align: center;
  border-bottom: 1px solid;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.andrana li:last-child {
  border-bottom: 0px solid !important;
}

ul.andrana li:hover,
ul.andrana2 li:hover i.mdg-text-white,
ul.andrana2 li:hover {
  color: #00CCFF !important;
  cursor: url("../../../public/assets/image/cursor_hover.png"), auto;
}


.mdg-historique-table .rdt_Table, .mdg-historique-table  .rdt_TableHead
{   color: #fff !important;
    background-color: transparent !important;
}


.mdg-historique-table .rdt_TableHeadRow, .mdg-historique-table .rdt_Pagination{
    color: #fff !important;
    background-color: #454d55 !important;
}

.mdg-historique-table #pagination-first-page,
.mdg-historique-table #pagination-previous-page,
.mdg-historique-table #pagination-last-page,
.mdg-historique-table #pagination-next-page {
    color: #fff !important;
    fill: #fff !important;   
}






.mdg-bg-gradient {
  position: relative;
  background-image: linear-gradient(to bottom, #3A434A , #101A1F);
  padding: 0!important;
  width: 100%;
  height: 100%;
}

.mdg-bg-gradient .height {
  height: 20%;
}

.mdg-form-input {
  background: #17a2b8;
  color: #fff;
  padding: 10px;
  border-radius: 20px;
  border: none;
}

.mdg-form-input::placeholder,
.mdg-form-input::-moz-placeholder,
.mdg-form-input::-webkit-input-placeholder {
  color: #fff;
}

.btn-transparent-icon {
  border: none;
  position: absolute;
  background: transparent;
  top: 5px;
  right: 20px;
}

.ls-select {
  border: 2px solid #17a2b8;
  border-radius: 5px;
  min-height: 100px;
  height: 200px;
  background: #326371;
  overflow-y: scroll;
}

.ls-select::-webkit-scrollbar {
  padding: 10px;
  width: 10px;
  display: block !important;
}

.ls-select::-webkit-scrollbar-thumb {
  background: #17a2b8;
  width: 5px;
  border-radius: 8px;
  margin: 5px;
  padding: 5px;
}

.li-select {
  padding: 4px 0;
  border-bottom: 1px solid #17a2b8;
}

.bg-blur {
  filter: blur(3px);
}


.mdg-icon-padding {
  padding: 8px 5px !important;
}

.mdg-body.dash {
  overflow-y: scroll;
}

.tab-content {
  min-height: auto;
  height: auto;
  overflow-y: auto; 
}

@media only screen and (max-height: 767px) { 
  .mdg-body {
    padding-top: 250px;
  }
  .mdg-body.dash {
    overflow-y: hidden;
  }
  .menu-left.menu-left.nav.flex-column.nav-pills {
    height: 510px;
    overflow-y: scroll;
    min-height: 510px;
    display: block;
    width: 100%;
  }
  .tab-content {
    min-height: 510px;
    height: 510px;
    overflow-y: scroll; 
  }
  .menu-left.menu-left.nav.flex-column.nav-pills button {
    width: 100% !important;
  }
  
}

@media only screen and (max-height: 575px) { 
  .mdg-body {
    padding-top: 350px;
  }
  .mdg-body.dash {
    overflow-y: hidden !important;
  }
  .menu-left.menu-left.nav.flex-column.nav-pills {
    height: 410px;
    overflow-y: scroll;
    min-height: 410px;
    display: block;
    width: 100%;
  }
  .tab-content {
    min-height: 410px;
    height: 410px;
    overflow-y: scroll; 
  }
  .menu-left.menu-left.nav.flex-column.nav-pills button {
    width: 100% !important;
  }
  
}

@media only screen and (max-height: 450px) { 
  .mdg-body {
    padding-top: 450px;
  }
  .mdg-body.dash {
    overflow-y: hidden !important;
  }
  .menu-left.menu-left.nav.flex-column.nav-pills {
    height: 290px;
    overflow-y: scroll;
    min-height: 290px;
    display: block;
    width: 100%;
  }
  .tab-content {
    min-height: 290px;
    height: 290px;
    overflow-y: scroll; 
  }
  .menu-left.menu-left.nav.flex-column.nav-pills button {
    width: 100% !important;
  }
  
}


.editorClassName.rdw-editor-main {
  background: white !important;
  padding-left: 10px;
  padding-right: 10px;
}