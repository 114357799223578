table th {
    border: 1px solid #ddd;
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #007a99;
    color: white;
}
table {
    border-collapse: collapse;width: 100%;
}

table td {
    border: 1px solid #ddd;padding: 8px;
}

.mdg-btn-deny {
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 7px 7px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 11px;
}

.mdg-btn-accept {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 7px 7px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 11px;
}