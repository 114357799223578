:focus {
    outline: none;
}

input[type="email"],
input[type="text"] {
    color: #333;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
}

.mdg-form-group-animate {
    float: left;
    width: 100%;
    margin: 5px 1%;
    position: relative;
}

.mdg-effect-1,
.mdg-effect-2,
.mdg-effect-3 {
    border: 0;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
}

.mdg-effect-1~.mdg-focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #210402;
    transition: 0.4s;
}

.mdg-effect-1:focus~.mdg-focus-border {
    width: 100%;
    transition: 0.4s;
}

.mdg-effect-2~.mdg-focus-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #210402;
    transition: 0.4s;
}

.mdg-effect-2:focus~.mdg-focus-border {
    width: 100%;
    transition: 0.4s;
    left: 0;
}

.mdg-effect-3~.mdg-focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 99;
}

.mdg-effect-3~.mdg-focus-border:before,
.mdg-effect-3~.mdg-focus-border:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #210402;
    transition: 0.4s;
}

.mdg-effect-3~.mdg-focus-border:after {
    left: auto;
    right: 0;
}

.mdg-effect-3:focus~.mdg-focus-border:before,
.mdg-effect-3:focus~.mdg-focus-border:after {
    width: 50%;
    transition: 0.4s;
}

.mdg-effect-4,
.mdg-effect-5,
.mdg-effect-6 {
    border: 0;
    padding: 5px 0 7px;
    border: 1px solid transparent;
    border-bottom-color: #ccc;
    transition: 0.4s;
}

.mdg-effect-4:focus,
.mdg-effect-5:focus,
.mdg-effect-6:focus {
    padding: 5px 14px 7px;
    transition: 0.4s;
}

.mdg-effect-4~.mdg-focus-border {
    position: absolute;
    height: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.4s;
    z-index: -1;
}

.mdg-effect-4:focus~.mdg-focus-border {
    transition: 0.4s;
    height: 36px;
    border: 2px solid #210402;
    z-index: 1;
}

.mdg-effect-5~.mdg-focus-border {
    position: absolute;
    height: 36px;
    bottom: 0;
    left: 0;
    width: 0;
    transition: 0.4s;
}

.mdg-effect-5:focus~.mdg-focus-border {
    width: 100%;
    transition: 0.4s;
    border: 2px solid #210402;
}

.mdg-effect-6~.mdg-focus-border {
    position: absolute;
    height: 36px;
    bottom: 0;
    right: 0;
    width: 0;
    transition: 0.4s;
}

.mdg-effect-6:focus~.mdg-focus-border {
    width: 100%;
    transition: 0.4s;
    border: 2px solid #210402;
}

.mdg-effect-7,
.mdg-effect-8,
.mdg-effect-9 {
    border: 1px solid #ccc;
    padding: 7px 14px 9px;
    transition: 0.4s;
}

.mdg-effect-7~.mdg-focus-border:before,
.mdg-effect-7~.mdg-focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #210402;
    transition: 0.4s;
}

.mdg-effect-7~.mdg-focus-border:after {
    top: auto;
    bottom: 0;
}

.mdg-effect-7~.mdg-focus-border i:before,
.mdg-effect-7~.mdg-focus-border i:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 0;
    background-color: #210402;
    transition: 0.6s;
}

.mdg-effect-7~.mdg-focus-border i:after {
    left: auto;
    right: 0;
}

.mdg-effect-7:focus~.mdg-focus-border:before,
.mdg-effect-7:focus~.mdg-focus-border:after {
    left: 0;
    width: 100%;
    transition: 0.4s;
}

.mdg-effect-7:focus~.mdg-focus-border i:before,
.mdg-effect-7:focus~.mdg-focus-border i:after {
    top: 0;
    height: 100%;
    transition: 0.6s;
}

.mdg-effect-8~.mdg-focus-border:before,
.mdg-effect-8~.mdg-focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #210402;
    transition: 0.3s;
}

.mdg-effect-8~.mdg-focus-border:after {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
}

.mdg-effect-8~.mdg-focus-border i:before,
.mdg-effect-8~.mdg-focus-border i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: #210402;
    transition: 0.4s;
}

.mdg-effect-8~.mdg-focus-border i:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
}

.mdg-effect-8:focus~.mdg-focus-border:before,
.mdg-effect-8:focus~.mdg-focus-border:after {
    width: 100%;
    transition: 0.3s;
}

.mdg-effect-8:focus~.mdg-focus-border i:before,
.mdg-effect-8:focus~.mdg-focus-border i:after {
    height: 100%;
    transition: 0.4s;
}

.mdg-form-group-animate i {
    position: absolute;
}

.mdg-m-icon {
    padding: 15px;
    left: 0;
}

.mdg-mp-3 {
    padding: 7px 40px 9px !important;
}

.mdg-textfield-outlined {
    position: relative;
    margin-bottom: 5px;
    padding-top: 6px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    width: 100%;
}

.mdg-textfield-outlined input,
.mdg-textfield-outlined select,
.mdg-textfield-outlined textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 9px 13px 9px;
    font-size: 14px;
    line-height: inherit;
    color: #272e36;
    border-style: solid;
    border-width: 1px;
    border-color: transparent #92969a #92969a;
    border-radius: 4px;
    -webkit-text-fill-color: currentColor;
    background-color: transparent;
    caret-color: #210402;
    transition: border 0.2s, box-shadow 0.2s;
}

.mdg-textfield-outlined input:not(:focus):placeholder-shown,
.mdg-textfield-outlined textarea:not(:focus):placeholder-shown {
    border-top-color: #92969a;
}

.mdg-top-7 div+label {    
    display: flex;
    width: 100%;
    max-height: 100%;
    position: absolute;    
    font-weight: normal !important;
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 15px;
    color: #92969a;
    cursor: text;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

.mdg-form-seller.mdg-top-7 div+label {    
    display: flex;
    width: 100%;
    max-height: 100%;
    position: absolute;    
    font-weight: normal !important;
    top: 0;
    left: 17px;
    font-size: 12px;
    line-height: 15px;
    color: #92969a;
    cursor: text;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

.mdg-textfield-outlined input+label,
.mdg-textfield-outlined textarea+label {
    display: flex;
    width: 100%;
    max-height: 100%;
    position: absolute;    
    font-weight: normal !important;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 15px;
    color: #92969a;
    cursor: text;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

.mdg-textfield-outlined input:not(:focus):placeholder-shown+label,
.mdg-textfield-outlined textarea:not(:focus):placeholder-shown+label {
    font-size: 14px;
    line-height: 54px;
}

.mdg-top-7 div+label::after,
.mdg-top-7 div+label::before {
    content: "";
    display: block;
    box-sizing: border-box;
    height: 8px;
    min-width: 10px;
    margin-top: 7px;
    border-top: solid 1px #92969a;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
    transition: border 0.2s;    
}

.mdg-top-7.mdg-form-seller div+label::after,
.mdg-top-7.mdg-form-seller div+label::before {
    content: "";
    display: block;
    box-sizing: border-box;
    height: 8px;
    min-width: 10px;
    margin-top: 7px;
    border-top: solid 1px #17A2B8;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
    transition: border 0.2s;    
}

.mdg-textfield-outlined input+label::before,
.mdg-textfield-outlined textarea+label::before,
.mdg-textfield-outlined input+label::after,
.mdg-textfield-outlined textarea+label::after {
    content: "";
    display: block;
    box-sizing: border-box;
    height: 8px;
    min-width: 10px;
    margin-top: 6px;
    border-top: solid 1px #92969a;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
    transition: border 0.2s;
}

.mdg-textfield-outlined input+label::before,
.mdg-textfield-outlined textarea+label::before {
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
}

.mdg-top-7 div+label::after{
    flex-grow: 0.87;
    margin-left: 8px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
}

.mdg-top-7.mdg-form-seller div+label::after{
    flex-grow: 0.93;
    margin-left: 8px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
}

.mdg-textfield-outlined input+label::after,
.mdg-textfield-outlined textarea+label::after {
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
}

.mdg-textfield-outlined input:not(:focus):placeholder-shown+label::before,
.mdg-textfield-outlined textarea:not(:focus):placeholder-shown+label::before,
.mdg-textfield-outlined input:not(:focus):placeholder-shown+label::after,
.mdg-textfield-outlined textarea:not(:focus):placeholder-shown+label::after {
    border-top-color: transparent;
}

.mdg-textfield-outlined input:focus,
.mdg-textfield-outlined textarea:focus {
    border-color: transparent #210402 #210402;
    outline: none;
}

.mdg-textfield-outlined input:focus+label,
.mdg-textfield-outlined textarea:focus+label {
    color: #210402;
}

.mdg-textfield-outlined input:focus+label::before,
.mdg-textfield-outlined textarea:focus+label::before,
.mdg-textfield-outlined input:focus+label::after,
.mdg-textfield-outlined textarea:focus+label::after {
    border-top-color: #210402;
}

.mdg-textfield-outlined input:disabled,
.mdg-textfield-outlined textarea:disabled,
.mdg-textfield-outlined input:disabled+label,
.mdg-textfield-outlined textarea:disabled+label {
    color: #210402;
    border-color: transparent #210402 #210402;
    cursor: not-allowed;
}

.mdg-textfield-outlined input:disabled+label::before,
.mdg-textfield-outlined textarea:disabled+label::before,
.mdg-textfield-outlined input:disabled+label::after,
.mdg-textfield-outlined textarea:disabled+label::after {
    border-top-color: #210402;
}

.mdg-textfield-outlined input:disabled:placeholder-shown,
.mdg-textfield-outlined textarea:disabled:placeholder-shown,
.mdg-textfield-outlined input:disabled:placeholder-shown+label,
.mdg-textfield-outlined textarea:disabled:placeholder-shown+label {
    border-top-color: #210402;
}

.mdg-textfield-outlined input:disabled:placeholder-shown+label::before,
.mdg-textfield-outlined textarea:disabled:placeholder-shown+label::before,
.mdg-textfield-outlined input:disabled:placeholder-shown+label::after,
.mdg-textfield-outlined textarea:disabled:placeholder-shown+label::after {
    border-top-color: transparent;
}

.mdg-textfield-outlined--error input,
.mdg-textfield-outlined--error textarea {
    border-color: transparent red red;
}

.mdg-textfield-outlined--error input+label,
.mdg-textfield-outlined--error textarea+label {
    color: red;
}

.mdg-textfield-outlined--error input+label::before,
.mdg-textfield-outlined--error textarea+label::before,
.mdg-textfield-outlined--error input+label::after,
.mdg-textfield-outlined--error textarea+label::after {
    border-top-color: red;
}

.mdg-textfield-outlined--error input:not(:focus):placeholder-shown,
.mdg-textfield-outlined--error textarea:not(:focus):placeholder-shown,
.mdg-textfield-outlined--error input:not(:focus):placeholder-shown+label,
.mdg-textfield-outlined--error textarea:not(:focus):placeholder-shown+label {
    border-top-color: red;
    color: red;
}

.mdg-textfield-outlined--error input:not(:focus):placeholder-shown+label::before,
.mdg-textfield-outlined--error textarea:not(:focus):placeholder-shown+label::before,
.mdg-textfield-outlined--error input:not(:focus):placeholder-shown+label::after,
.mdg-textfield-outlined--error textarea:not(:focus):placeholder-shown+label::after {
    border-top-color: transparent;
}

.mdg-textfield-outlined--error input:focus,
.mdg-textfield-outlined--error textarea:focus {
    border-color: transparent red red;
}

.mdg-textfield-outlined--error input:focus+label,
.mdg-textfield-outlined--error textarea:focus+label {
    color: red;
}

.mdg-textfield-outlined--error input:focus+label::before,
.mdg-textfield-outlined--error textarea:focus+label::before,
.mdg-textfield-outlined--error input:focus+label::after,
.mdg-textfield-outlined--error textarea:focus+label::after {
    border-top-color: red;
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .mdg-textfield-outlined > input, .mdg-textfield-outlined > input + label, .mdg-textfield-outlined > input + label::before, .mdg-textfield-outlined > input + label::after, .mdg-textfield-outlined > textarea, .mdg-textfield-outlined > textarea + label, .mdg-textfield-outlined > textarea + label::before, .mdg-textfield-outlined > textarea + label::after {
            transition-duration: 0.1s;
        }
    }
}
