.mdg-dropdown-menu.show {
    display: block
}

.mdg-nav-tabs .mdg-nav-item.show .mdg-nav-link,
.mdg-nav-tabs .mdg-nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.mdg-navbar .mdg-navbar-nav li.mdg-menu-item-has-children.show .mdg-sub-menu {
    max-height: 1000px;
    opacity: 1;
    position: static !important;
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
}

.mdg-thumbnail {
    width: 40px;
}

body {
    /* background: #e8edf5; */
    width: 100%;
    height: 100%;
    font-family: 'Ubuntu' !important;
    font-style: normal;
}

.mdg-wrapper .mdg-top_navbar {
    width: 100%;
    height: 65px;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 11111111111111;
}

.mdg-wrapper .mdg-top_navbar .mdg-logo {
    width: 250px;
    height: 100%;
    background: #000;
    border-bottom: 1px solid #0f0f0f;
}

.mdg-wrapper .mdg-top_navbar .mdg-logo a {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #fff;
}

.mdg-hidden {
    display: none !important;
}

.mdg-wrapper .mdg-top_navbar .mdg-top_menu {
    width: calc(100% - 250px);
    height: 100%;
    background: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    clear: both;
    -webkit-box-shadow: none;
}

.mdg-wrapper .mdg-top_navbar .mdg-top_menu .home_link a {
    display: block;
    background: #000;
    color: #fff;
    padding: 8px 15px;
    border-radius: 3px;
}

.mdg-wrapper .mdg-top_navbar .mdg-top_menu .home_link a:hover,
.mdg-wrapper .mdg-top_navbar .right_info .icon_wrap:hover {
    background: grey;
}

.mdg-wrapper .mdg-top_navbar .right_info {
    display: flex;
}

.mdg-wrapper .mdg-top_navbar .right_info .icon_wrap {
    padding: 8px 15px;
    border-radius: 3px;
    background: #000;
    color: #fff;
    margin: 0 5px;
    cursor: pointer;
}

.mdg-main_body .mdg-sidebar_menu {
    position: fixed;
    top: 65px;
    left: 0;
    background: #000;
    width: 250px;
    height: 100%;
    transition: all 0.3s linear;
    z-index: 111111111111;
}

.mdg-main_body .mdg-sidebar_menu .inner__mdg-sidebar_menu {
    position: relative;
    padding-top: 60px;
}

.mdg-main_body .mdg-sidebar_menu ul li a {
    color: #191919;
    font-size: 18px;
    padding: 20px 35px;
    display: block;
    white-space: nowrap;
    color: white;
}

.mdg-main_body .mdg-sidebar_menu ul li a .icon {
    margin-right: 8px;
}

.mdg-main_body .mdg-sidebar_menu ul li a span {
    display: inline-block;
}

.mdg-main_body .mdg-sidebar_menu ul li a:hover {
    background: #101010;
    color: #fff;
}

.mdg-main_body .mdg-sidebar_menu ul li a.active {
    background: #101010;
    color: #fff;
}

.mdg-main_body .mdg-sidebar_menu .hamburger {
    position: absolute;
    top: 5px;
    right: -25px;
    width: 50px;
    height: 50px;
    background: #e8edf5;
    border-radius: 50%;
    cursor: pointer;
}

.mdg-main_body .mdg-sidebar_menu .inner_hamburger,
.mdg-main_body .mdg-sidebar_menu .hamburger .arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mdg-main_body .mdg-sidebar_menu .inner_hamburger {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    background: #000;
}

.mdg-main_body .mdg-sidebar_menu .hamburger .arrow {
    color: #fff;
    font-size: 20px;
}

.mdg-main_body .mdg-sidebar_menu .hamburger .fa-long-arrow-alt-right {
    display: none;
}

.mdg-main_body .container {
    width: calc(100% - 250px);
    margin-top: 65px;
    margin-left: 250px;
    /* padding: 25px 40px; */
    transition: all 0.3s linear;
}

.mdg-main_body .container .item_wrap {
    display: flex;
    margin-bottom: 20px;
}

.mdg-main_body .container .item_wrap .item {
    background: #fff;
    border: 1px solid #e0e0e0;
    padding: 25px;
    font-size: 14px;
    line-height: 22px;
}

.mdg-main_body .container .item_wrap .item:first-child {
    margin-right: 20px;
}


/* after adding active class */

.mdg-wrapper.active .mdg-sidebar_menu {
    width: 100px;
}

.mdg-wrapper.active .hamburger .fa-long-arrow-alt-right {
    display: block;
}

.mdg-wrapper.active .hamburger .fa-long-arrow-alt-left {
    display: none;
}

.mdg-wrapper.active .mdg-sidebar_menu ul li a .list {
    display: none;
}

.mdg-wrapper.active .mdg-main_body .container {
    width: calc(100% - 100px);
    margin-left: 100px;
}

.mdg-show-modal {
    display: block !important;
}

.mdg-modal {
    position: absolute;
    z-index: 10000;
    /* 1 */
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
}

.mdg-modal.is-visible {
    visibility: visible;
}

.mdg-modal-overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.mdg-modal.is-visible .mdg-modal-overlay {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

.mdg-modal-wrapper {
    position: absolute;
    z-index: 9999;
    top: 6em;
    left: 50%;
    width: 32em;
    margin-left: -16em;
    background-color: #fff;
    box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.mdg-modal-wrapper-large {
    position: relative;
    z-index: 9999;
    top: 6em;
    left: 0%;
    width: 55em;
    margin: 10rem auto 13rem auto;
    border: 1px solid #17A2B8;
    background-color: #34393C;
    box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.mdg-modal-transition {
    transition: all 0.3s 0.12s;
    transform: translateY(-10%);
    opacity: 0;
}

.mdg-modal.is-visible .mdg-modal-transition {
    transform: translateY(0);
    opacity: 1;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px !important;
}

.mdg-modal-header,
.mdg-modal-footer,
.mdg-modal-content {
    padding: 1em;
}

.mdg-modal-header {
    position: relative;
    /* box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
    border-bottom: 1px solid #17A2B8; */
    border-top-left-radius: 20px !important;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}

.mdg-modal-footer {
    bottom: 0px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.mdg-modal-madigi {
    background-color: #210402 !important;
    color: #fff;
}

.mdg-modal-success {
    background-color: #28a745 !important;
    color: #fff;
}

.mdg-modal-danger {
    background-color: #c82333 !important;
    color: #fff;
}

.mdg-modal-warning {
    background-color: #d39e00 !important;
    color: #fff;
}

.mdg-modal-primary {
    background-color: #007bff !important;
    color: #fff;
}

.mdg-modal-default {
    background-color: #fff !important;
    color: #000;
}

.mdg-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    color: #fff;
    background: none;
    border: 0;
    cursor: pointer;
}

.mdg-modal-close:hover {
    color: #777;
}

.mdg-modal-heading {
    color: #fff;
    font-size: 1.125em;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mdg-modal-content>*:first-child {
    margin-top: 0;
}

.mdg-modal-content>*:last-child {
    margin-bottom: 0;
}

.mdg-body-andrana {

    background: url(/public/assets/img/bg-font-login.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mdg-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
}

h1.mdg-login-text-title {
	font-weight: bold;
	margin: 0;
}

span.mdg-text-span {
	font-size: 12px;
}

a.mdg-login-social {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

form.mdf-form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
    justify-content: center;
	flex-direction: column;
	padding: 0 20px;
	height: 100%;
	text-align: center;
    margin: 10px 0 0 0;
}
.mdg-login-container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25),
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 900px;
	max-width: 100%;
	min-height: 650px;
}

.mdg-form-login-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.mdg-login-container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.mdg-login-container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.mdg-login-container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #00CCFE;
	background: -webkit-linear-gradient(to right, #04aed8, #00CCFE);
	background: linear-gradient(to right, #04aed8, #00CCFE);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.mdg-login-container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.mdg-login-container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.mdg-login-container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.mdg-d-none{
    display: none !important;
}

#map {
    height: 100%;
    width: 100%;
    position:absolute;
    top: 0;
    left: 0;
    z-index: 0; /* Set z-index to 0 as it will be on a layer below the contact form */
}

#contactform {
    position: relative;
    z-index: 1;
}

.mdg-head-login{
    z-index: 1;
    background: #fff;
    top: 0;
    /* left: 0; */
    position: absolute;
    height: 60px;
    border-radius: 0% 0% 50% 50%;
    width: 20%;
    text-align: center;
}
.mdg-logo-head{
    height: 50px;
}

.mdg-bg-footer{
    bottom: 5px;
    right: 0;
    width: 15%;
    position: absolute;
    text-align: right;
}

.mdg-bg-footer-off{
    bottom: 100px;
    right: 0;
    width: 15%;
    position: absolute;
    text-align: right;
}

.mdg-bg-footer .mdg-footer-title{
    margin-right: 50px;
}

.mdg-bg-footer .mdg-span-footer{
    padding: 4px 20px 4px 150px;
    background: #00CCFE;
    color: #fff;
}


.content-slantedcard {
    margin-right: auto;
    margin-left: auto;
    padding: 0 2px;
}

.mdg-section-slantedcard-card {
    padding: 10px 15px 10px;
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);
    background-color: white;
}

.mdg-section-slantedcard-bottom {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transform: rotate(-2deg);
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);
    background-color: #00CCFE;
}

.mdg-section-slantedcard-top {
    position: relative;
    z-index: 10;
}


.content-slantedcard-text {
    line-height: 1.6;
}

@media (max-width: 767px) {
    .mdg-section-slantedcard-card {
        padding: 10px 10px 10px;
    }

    .mdg-section-slantedcard-bottom {
        display: none;
    }

    .mdg-section-slantedcard-top {
        position: relative;
        z-index: 10;
    }

    h1.mdg-login-text-title {
        font-size: 10px;
    }

    .mdg-bg-footer{
        bottom: 5px;
        right: auto;
        width: 100%;
        position: absolute;
        text-align: right;
    }

    .mdg-bg-footer-off{
        bottom: 100px;
        right: auto;
        width: 100%;
        position: absolute;
        text-align: right;
    }

}





/** template css */


a {
    text-decoration: none !important;
}

.navList__subheading-title>a {
    text-decoration: none !important;
    color: #fff !important;
}

.text-light {
    font-weight: 300;
}

.text-bold {
    font-weight: bold;
}

.mdg-row-d {
    display: flex;
}

.row--align-v-center {
    align-items: center;
}

.row--align-h-center {
    justify-content: center;
}

.mdg-grid-d {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px 1fr 50px;
    grid-template-areas: 'header''main''footer';
    height: 100vh;
    overflow-x: hidden;
}

.grid--noscroll {
    overflow-y: hidden;
}

.mdg-header-d {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    padding: 5px 0;
    position: fixed;
    z-index: 1;
    width: calc(100% - 240px);
    right: 0;
}

.header__menu {
    position: fixed;
    padding: 13px;
    left: 12px;
    background-color: #dadae3;
    border-radius: 50%;
    z-index: 1;
}

.header__menu:hover {
    cursor: pointer;
}

.header__search {
    margin-left: 55px;
    font-size: 20px;
    color: #777;
}

.header__input {
    border: none;
    background: transparent;
    padding: 12px;
    font-size: 20px;
    color: #777;
}

.header__input:focus {
    outline: none;
    border: none;
}

.header__avatar {
    cursor: pointer;
    border: 3px solid #00CCFE;
    color: #00CCFE;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}

.header__avatar:after {
    content: "";
    width: 6px;
    height: 6px;
    top: 50%;
    right: -18px;
}

.mdg-other-card {
    margin-top: 5px;
}

.mdg-dropdown-d {
    position: absolute;
    top: 54px;
    right: -16px;
    width: 220px;
    height: auto;
    z-index: 1;
    background-color: #fff;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.dropdown__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.dropdown__list-item {
    padding: 12px 24px;
    color: #777;
    text-transform: capitalize;
}

.dropdown__list-item:hover {
    background-color: rgba(0, 0, 0, .1);
}

.dropdown__icon {
    color: #777;
}

.dropdown__title {
    margin-left: 10px;
}
/*
.dropdown:before {
    position: absolute;
    content: "";
    top: -6px;
    right: 30px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #fff;
} */

.dropdown--active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.mdg-sidenav-d {
    position: fixed;
    grid-area: sidenav;
    height: 100%;
    overflow-y: auto;
    background-color: #000;
    color: #00CCFE;
    width: 240px;
    transform: translateX(-245px);
    transition: all 0.6s ease-in-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    z-index: 2;
}

.sidenav__brand {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 50px;
    background-color: rgba(0, 0, 0, .15);
}

.sidenav__brand-icon {
    margin-top: 2px;
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
}

.sidenav__brand-close {
    position: absolute;
    right: 8px;
    top: 8px;
    visibility: visible;
    color: rgba(255, 255, 255, .5);
    cursor: pointer;
}

.sidenav__brand-link {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin: 0 15px;
    letter-spacing: 1.5px;
}

.sidenav__profile {
    display: flex;
    align-items: center;
    min-height: 90px;
    background-color: rgba(255, 255, 255, .1);
}

.sidenav__profile-avatar {
    /* background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1609106/headshot.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, .2);
    height: 64px;
    width: 64px;
    margin: 0 15px;
}

.sidenav__profile-title {
    font-size: 17px;
    letter-spacing: 1px;
}

.sidenav__arrow {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    top: 50%;
    right: 20px;
    border-left: 2px solid rgba(255, 255, 255, .5);
    border-bottom: 2px solid rgba(255, 255, 255, .5);
    transform: translateY(-50%) rotate(225deg);
}

.sidenav__sublist {
    list-style-type: none;
    margin: 0;
    padding: 10px 0 0;
}

.sidenav--active {
    transform: translateX(0);
}

.navList {
    width: 240px;
    padding: 0;
    margin: 0;
    background-color: #000;
    list-style-type: none;
}

.navList__heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 3px;
    color: #00CCFE;
    text-transform: uppercase;
    font-size: 15px;
}

.navList__subheading {
    position: relative;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
}

.navList__subheading-i {
    position: relative;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
}

.navList__subheading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
    width: 12px;
}

.navList__subheading-title {
    margin: 0 15px;
}

.navList__subheading:after {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    top: 17px;
    right: 25px;
    border-left: 1px solid rgba(255, 255, 255, .5);
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    transform: rotate(225deg);
    transition: all 0.2s;
}

.navList__subheading-i:after {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    top: 17px;
    right: 25px;
}

.navList__subheading:hover {
    background-color: #00CCFE;
    cursor: pointer;
}

.navList__subheading-i:hover {
    background-color: #00CCFE;
    cursor: pointer;
}

.active>.navList__subheading-i {
    background-color: #00CCFE;
    cursor: pointer;
}

.navList__subheading--open {
    background-color: #00CCFE;
}

.navList__subheading--open:after {
    transform: rotate(315deg);
}

.navList .subList {
    padding: 0;
    margin: 0;
    list-style-type: none;
    background-color: #528b99;
    visibility: visible;
    overflow: hidden;
    max-height: 200px;
    transition: all 0.4s ease-in-out;
}

.navList .subList__item {
    padding: 8px;
    text-transform: capitalize;
    padding: 8px 30px;
    color: #d3d3d3;
}

.navList .subList__item:first-child {
    padding-top: 15px;
}

.navList .subList__item:hover {
    background-color: rgba(255, 255, 255, .1);
    cursor: pointer;
}

.navList .subList--hidden {
    visibility: hidden;
    max-height: 0;
}

.main {
    grid-area: main;
    background-color: #eaedf1;
    color: #394263;
}

.main__cards {
    display: block;
    column-count: 1;
    column-gap: 20px;
    margin: 20px;
}

.mdg-content-main {
    display: block;
    column-count: 1;
    column-gap: 20px;
    margin: 20px;
}

.main .main-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 250px;
    color: #fff;
    background-size: cover;
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1609106/lake-shadow-water.jpg");
    margin-bottom: 20px;
}

.main-header__intro-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 160px;
    padding: 12px 30px;
    background: rgba(255, 255, 255, .12);
    font-size: 26px;
    letter-spacing: 1px;
}

.main-header__welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-header__welcome-title {
    margin-bottom: 8px;
    font-size: 26px;
}

.main-header__welcome-subtitle {
    font-size: 18px;
}

.quickview {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 60px;
}

.quickview__item {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.quickview__item-total {
    margin-bottom: 2px;
    font-size: 32px;
}

.quickview__item-description {
    font-size: 16px;
    text-align: center;
}

.main-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    grid-auto-rows: 94px;
    grid-gap: 30px;
    margin: 20px;
}

.overviewCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: #fff;
    transform: translateY(0);
    transition: all 0.3s;
}

.overviewCard-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 21px;
    color: #fff;
}

.overviewCard-icon--document {
    background-color: #e67e22;
}

.overviewCard-icon--calendar {
    background-color: #27ae60;
}

.overviewCard-icon--mail {
    background-color: #e74c3c;
}

.overviewCard-icon--photo {
    background-color: #af64cc;
}

.overviewCard-description {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overviewCard-title {
    font-size: 18px;
    color: #1bbae1;
    margin: 0;
}

.overviewCard-subtitle {
    margin: 2px;
    color: #777;
}

/* .overviewCard:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    cursor: pointer;
} */

.mdg-card-d {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;
    -webkit-column-break-inside: avoid;
}

.card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #394263;
    color: #fff;
}

.card__header-title {
    margin: 0 20px;
    font-size: 20px;
    letter-spacing: 1.2px;
}

.card__header-link {
    font-size: 16px;
    color: #1bbae1;
    letter-spacing: normal;
    display: inline-block;
}

.card__main {
    position: relative;
    padding-right: 20px;
    background-color: #fff;
}

.card__main:after {
    content: "";
    position: absolute;
    top: 0;
    left: 120px;
    bottom: 0;
    width: 2px;
    background-color: #f0f0f0;
}

.card__secondary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 100px;
    grid-gap: 25px;
    padding: 20px;
    background-color: #fff;
}

.card__photo {
    background-image: url('/public/assets/img/bg-font-login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: slategray;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 100%;
}

.card__photo:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.card__photo-wrapper {
    overflow: hidden;
}

.card__row {
    position: relative;
    display: flex;
    flex: 1;
    margin: 15px 0 20px;
}

.card__icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    width: 30px;
    height: 30px;
    top: 0;
    left: 121px;
    transform: translateX(-50%);
    border-radius: 50%;
    color: #fff;
    background-color: #1bbae1;
    z-index: 1;
}

.card__row:nth-child(even) .card__icon {
    background-color: #e74c3c;
}

.card__time {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    max-width: 80px;
    margin-left: 15px;
    text-align: right;
    font-size: 14px;
    line-height: 2;
}

.card__detail {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 12px;
    margin-left: 48px;
    transform: translateX(0);
    transition: all 0.3s;
}

.card__detail:hover {
    background-color: #f0f0f0;
    transform: translateX(4px);
    cursor: pointer;
}

.card__source {
    line-height: 1.8;
    color: #1bbae1;
}

.card__note {
    margin: 10px 0;
    color: #777;
}

.card--finance {
    position: relative;
}

.settings {
    display: flex;
    margin: 8px;
    align-self: flex-start;
    background-color: rgba(255, 255, 255, .5);
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
}

.settings__block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: #394263;
    font-size: 11px;
}

.settings__block:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, .1);
}

.settings__icon {
    padding: 0px 3px;
    font-size: 12px;
}

.settings__icon:hover {
    background-color: rgba(255, 255, 255, .8);
    cursor: pointer;
}

.settings:hover {
    background-color: #fff;
    cursor: pointer;
}

.documents {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
    grid-auto-rows: 214px;
    grid-gap: 12px;
    height: auto;
    background-color: #fff;
}

.document {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0 0;
    flex-direction: column;
}

.document__img {
    width: 105px;
    height: 136px;
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1609106/doc-1.png");
    background-size: cover;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.document__img:hover {
    transform: translateY(-4px);
}

.document__title {
    margin: 8px 0 2px;
    color: #777;
}

.document__date {
    font-size: 10px;
}

#chartdiv {
    width: 100%;
    height: 300px;
    font-size: 11px;
    min-width: 0;
}

.footer {
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: #777;
    background-color: #fff;
}

.footer__copyright {
    color: #1bbae1;
}

.footer__icon {
    color: #e74c3c;
}

.footer__signature {
    color: #1bbae1;
    cursor: pointer;
    font-weight: bold;
}

.mdg__image {
    width: 60%;
}

@media only screen and (min-width: 46.875em) {
    .mdg-grid-d {
        display: grid;
        grid-template-columns: 240px calc(100% - 240px);
        grid-template-rows: 50px 1fr 50px;
        grid-template-areas: 'sidenav header''sidenav main''sidenav footer';
        height: 100vh;
    }

    .mdg-sidenav-d {
        position: fixed;
        transform: translateX(0);
    }

    .sidenav__brand-close {
        visibility: hidden;
    }

    .main-header__intro-wrapper {
        padding: 0 30px;
    }

    .header__menu {
        display: none;
    }

    .header__search {
        margin-left: 20px;
    }

    .header__avatar {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width: 65.625em) {
    .main__cards {
        column-count: 2;
    }

    .main-header__intro-wrapper {
        flex-direction: row;
    }

    .main-header__welcome {
        align-items: flex-start;
    }
}

@media only screen and (max-width: 767px) {
    .header__avatar {
        text-align: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
    }

    .mdg-header-d {
        width: auto;
        position: absolute;
        z-index: auto;
    }
}

/** end template css */




.mdg-width-btn{
    width: 70%;
}

.mdg-width-login{
    width: 650px !important;
    max-width: 100%;
    min-height: 450px !important;
}


a.mdg-btn {
    text-decoration: none;
}

a.mdg-a-navig {
    text-decoration: none;
    color: #b1aeae;
}

a.mdg-a-navig:hover {
    color: #fff;
    border-radius: 5px;
}

span>small.mdg-nav-span {
    color: #b1aeae;
}
.mdg-nav-span-profil {
    color: #fff;
    font-size: 35px;
}
.mdg-nav-span-profil-text {
    font-size: 35px;
}
.mdg-upload-img {
    max-height: 250px;
    max-width: 250px;
    min-width: 250px;
    min-height: 250px;
    object-fit: fill;
    cursor: pointer;
    box-shadow: 0 0 30px rgb(0 0 0 / 15%);
    padding: 10px;
    z-index: 0;
}

.mdg-upload-img-profil {
    max-height: 250px;
    max-width: 250px;
    min-width: 250px;
    min-height: 250px;
    border-radius: 50%;
    object-fit: fill;
    cursor: pointer;
    box-shadow: 0 0 30px rgb(0 0 0 / 15%);
    padding: 30px;
    z-index: 0;
}

.mdg-upload-img-profil-width {
    max-height: 150px;
    max-width: 150px;
    min-width: 150px;
    min-height: 150px;
    border-radius: 50%;
    object-fit: fill;
    cursor: pointer;
    box-shadow: 0 0 30px rgb(0 0 0 / 15%);
    padding: 10px;
    z-index: 0;
}

.mdg-upload-img-detail {
    max-height: 80px;
    max-width: 80px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 50%;
    object-fit: fill;
    cursor: pointer;
    box-shadow: 0 0 30px rgb(0 0 0 / 15%);
    /* padding: 30px; */
    z-index: 0;
}


.mdg-upload-img:hover {
    border-style: dashed;
    z-index: 1;
}

.mdg-upload-img-profil:hover {
    border-style: dashed;
    z-index: 1;
}

.dropdown__list-item>a{
    color: #777 !important;
}

.map-container {
    height: 400px;
    width: 400px;
}


.mdg-nav-span-user-dashboard {
    color: #fff;
    font-size: 20px;
}

.mdg-blur-text{
    font-size: 25px;
    color: transparent;
    text-shadow: 0 0 6px #fff;
}

@media screen and (max-width: 600px) {
    .map-container {
        height: 400px;
        width: 250px;
    }
}

@media screen and (min-width: 1400px) {
    .map-container {
        height: 400px;
        width: 750px;
    }
}




input[type="text"]:invalid.mdg-color-input {
    outline: 2px solid red;
}



.nav-link.seller.active {
    background-color: #181F23 !important;
}

.link-style{
    color: inherit;
}
.link-style:hover{
    background-color: inherit;
}
