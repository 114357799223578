.markerTooltip {
    text-align: left;
    color: white;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: RGBA(12, 18, 23, 0.8);
    border: none;
    font-size: 1em;
    font-weight: bold;
  }
  