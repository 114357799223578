.mapContainer {
    z-index: 2;
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

.mapDark{
  background-color: #262626 !important;
}
  